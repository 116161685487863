import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getYoutubeIDFromURL, getVimeoIDFromURL } from '../lib/helpers';
import IconFullscreen from './IconFullscreen';

class Media extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
          touch: false,
          hover: false
      }
    }

    render() {
        const {type, useFullImg} = this.props;
        if (type === "Image") {
            return (
                <figure
                    className={classnames(
                        "media-figure",
                        {
                            'hover': this.state.hover,
                            'not-hover': !this.state.hover,
                            'touch': this.state.touch
                        }
                    )}
                    onTouchStart={() => {this.setState({hover: !this.state.hover, touch: true})}}
                >
                    {useFullImg &&
                        <img src={this.props.url} alt={this.props.altText} style={{width: '100%'}} />
                    }

                    {!useFullImg &&
                        <div
                            className="media-image"
                            style={{backgroundImage: `url(${this.props.url})`, backgroundPosition: `${this.props.x} ${this.props.y}`}}
                            role="img"
                            aria-label={this.props.altText}
                        />
                    }

                    {(this.props.credit || this.props.caption) &&
                        <figcaption className="container-fluid">
                            <span className="caption">{this.props.caption} {(this.props.credit && this.props.caption) && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>} {this.props.credit}</span>
                        </figcaption>
                    }
                    
                    {(typeof this.props.openLightbox === 'function') && <button onClick={() => {this.props.openLightbox(this.props.id)}} className="lightbox-btn"><IconFullscreen /></button>}
                </figure>
            );
        }
        if (type === "Video") {
            return (
                <video controls>
                    <source src={this.props.url} type="video/mp4" />
                </video>
            );
        }
        if (type === "Youtube" || type === "Vimeo" || type === "Facebook") {
            let videoId = ""; let videoSrc = "";
            if (type === 'Youtube') {
                videoId = getYoutubeIDFromURL(article.youtubeUrl);
                videoSrc = `https://www.youtube.com/embed/${videoId}?controls=1&autoplay=1&showinfo=0&rel=0&loop=0`;
            }
            if (type === 'Vimeo') {
                videoId = getVimeoIDFromURL(article.vimeoUrl);
                videoSrc = `https://player.vimeo.com/video/${videoId}?autoplay=1&badge=0&byline=0&color=000000&portrait=0&title=0`;
            }
            if (type === 'Facebook') {
                videoSrc = article.facebookUrl;
            }
            return (
                <div className="video-wrapper--background">
                    <div className="video-wrapper--width">
                        <div className="video-wrapper--height">
                            <iframe src={videoSrc} frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            );
        }
        return (<span></span>)
    }
}

Media.propTypes =  {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    url: PropTypes.string,
    altText: PropTypes.string,
    credit: PropTypes.string,
    caption: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string,
    useFullImg: PropTypes.bool
}

Media.defaultProps = {
    x: "50%",
    y: "50%"
}

export default Media;
