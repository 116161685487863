import API from '../lib/api';
import {LIMIT} from "../constants";
const SET_HOME_PAGE = "HP/SET_HOME_PAGE";
const SET_HOME_PAGE_LIST = "HP/SET_HOME_PAGE_LIST";
const SET_IS_FETCHING_HOME_PAGE = "HP/SET_IS_FETCHING_HOME_PAGE";
const SET_IS_FETCHING_HOME_PAGE_LIST = "HP/SET_IS_FETCHING_HOME_PAGE_LIST";

const defaultState = {
    hpCarousel: [],
    hpFeatures: [],
    hpList: [],
    hpListCount: 0,
    socialMediaTags: {},
    isFetchingHomePage: false,
    isFetchingHpList: false,
};

export function fetchHomePage() {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_HOME_PAGE, payload: true});

        return new Promise((resolve, reject) => {
            return API.get(
                `${process.env.RAZZLE_API_HOST}/1/service/home`,
            ).then((res) => {
                dispatch({
                    type: SET_HOME_PAGE,
                    payload: {
                        carousel: res.data.carousel,
                        features: res.data.secondaryFeatures,
                        social: res.data.socialMediaTags
                    }
                });
                dispatch({type: SET_IS_FETCHING_HOME_PAGE, payload: false});
                resolve();
            }).catch((error) => {
                dispatch({type: SET_IS_FETCHING_HOME_PAGE, payload: false});
                resolve({error});
            });
        });

    }
}

export function fetchHomePageList(page) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_HOME_PAGE_LIST, payload: true});
        let _page = page || 1;

        return new Promise((resolve, reject) => {
            return API.get(
                `${process.env.RAZZLE_API_HOST}/1/home/articles?page=${_page}&limit=${LIMIT}`,
            ).then((res) => {
                let _list = res.data.data;
                if (_page > 1) {
                    let currentResults = getState().homePage.hpList;
                    _list = currentResults.concat(res.data.data);
                }
                dispatch({type: SET_HOME_PAGE_LIST, payload: {list: _list, count: res.data.count}});
                dispatch({type: SET_IS_FETCHING_HOME_PAGE_LIST, payload: false});
                resolve();
            }).catch((error) => {
                dispatch({type: SET_IS_FETCHING_HOME_PAGE_LIST, payload: false});
                resolve({error});
            });
        });

    }
}

export function fetchMoreHomePageList() {
    return (dispatch, getState) => {
        let total = getState().homePage.hpList.length;
        let nextPage = Math.ceil(total / LIMIT) + 1;
        dispatch(fetchHomePageList(nextPage));
    }
}

export function homePageReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_HOME_PAGE:
        return Object.assign({}, state, {
            hpCarousel: action.payload.carousel,
            hpFeatures: action.payload.features,
            socialMediaTags: action.payload.social
        });
        case SET_IS_FETCHING_HOME_PAGE:
        return Object.assign({}, state, {
            isFetchingHomePage: action.payload
        });
        case SET_HOME_PAGE_LIST:
        return Object.assign({}, state, {
            hpList: action.payload.list,
            hpListCount: action.payload.count
        });
        case SET_IS_FETCHING_HOME_PAGE_LIST:
        return Object.assign({}, state, {
            isFetchingHpList: action.payload
        });
        default:
        return state;
    }
}
