import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchSearchResults, fetchMoreSearchResults, setKeywords, setCategories, setAuthors } from '../redux/Search';
import Checkbox from '../audi-ui-components/Checkbox';
import Button from '../audi-ui-components/Button';
import _debounce from 'lodash/debounce';
import _uniqBy from 'lodash/uniqBy';
import _flatten from 'lodash/flatten';
import _pull from 'lodash/pull';
import _isEqual from 'lodash/isEqual';
import List from './List';
import Spinner from '../audi-ui-components/Spinner';

const mapStateToProps = state => {
    return {
        keywords: state.search.keywords || '',
        keywordsChanged: state.search.keywordsChanged || false,
        results: state.search.results || [],
        resultsCount: state.search.resultsCount || 0,
        categories: state.search.categories || [],
        authors: state.search.authors || [],
        selectedCategories: state.search.selectedCategories || [],
        selectedAuthors: state.search.selectedAuthors || [],
        isSearching: state.search.isSearching || false,
        hasSearched: state.search.hasSearched || false
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchSearchResults: (page, keywords) => {dispatch(fetchSearchResults(page, keywords));},
        fetchMoreSearchResults: () => {dispatch(fetchMoreSearchResults());},
        setKeywords: (words) => {dispatch(setKeywords(words));},
        setCategories: (cats) => {dispatch(setCategories(cats));},
        setAuthors: (auths) => {dispatch(setAuthors(auths));}
    }
}

class SearchForm extends React.Component {

    constructor(props) {
        super(props);
        this._debouncedFetch = _debounce(props.fetchSearchResults, 1000);
        this.state = {
        }
    }
    
    componentDidUpdate(prevProps) {
        // set focus on input
        if (!prevProps.isActive && this.props.isActive) {
            this.searchInput.focus();
        }
        // check if selected categories/authors changed
        const {selectedCategories, selectedAuthors} = this.props;
        let reFilter = false;
        if (selectedCategories.length !== prevProps.selectedCategories.length || selectedAuthors.length !== prevProps.selectedAuthors.length) {
            reFilter = true;
        } else {
            let categoriesEq = _isEqual(selectedCategories, prevProps.selectedCategories);
            let authorsEq = _isEqual(selectedAuthors, prevProps.selectedAuthors);
            reFilter = !categoriesEq || !authorsEq;
        }
        if (reFilter) { this._debouncedFetch(); }
    }

    handleKeywordsChange = (e) => {
        let v = e.target.value;
        this.props.setKeywords(v);
        this._debouncedFetch();
    }

    handleCategoryChange = (categorySlug) => {
        let _selectedCategories = this.props.selectedCategories.slice(0);
        if (_selectedCategories.indexOf(categorySlug) === -1) {
            _selectedCategories.push(categorySlug);
        } else {
            _pull(_selectedCategories, categorySlug);
        }
        this.props.setCategories(_selectedCategories);
    }

    handleAuthorChange = (authorSlug) => {
        let _selectedAuthors = this.props.selectedAuthors.slice(0);
        if (_selectedAuthors.indexOf(authorSlug) === -1) {
            _selectedAuthors.push(authorSlug);
        } else {
            _pull(_selectedAuthors, authorSlug);
        }
        this.props.setAuthors(_selectedAuthors);
    }

    render() {
        const {keywords, keywordsChanged, results, resultsCount, categories, authors, selectedCategories, selectedAuthors, isSearching, hasSearched} = this.props;
        const showLoadMore = !isSearching && results.length > 0 && results.length < resultsCount && keywords.length > 0 && !keywordsChanged;
        return (
            <div className="search-form">
                <div className="search-input-wrapper">
                    <input ref={(el) => this.searchInput = el} type="text" className="text-input" placeholder="What are you looking for?" value={keywords} onChange={this.handleKeywordsChange} />
                </div>

                {(results.length > 0 || isSearching || hasSearched) && <div className="row">
                    <div className="col-4 col-small-3">
                        <p className="search-heading">Filter Results</p>
                        {categories.length > 0 && <p className="search-subheading">Category</p>}
                        {categories.map((c, i) => {
                            return (
                                <p key={`filter_${c.slug}`} className="search-filter">
                                    <Checkbox name="filter-category" value={c.slug} checked={selectedCategories.indexOf(c.slug) !== -1} label={c.name} onChange={(v) => {this.handleCategoryChange(c.slug, v);}} />
                                </p>
                            );
                        })}
                        <br />
                        {authors.length > 0 && <p className="search-subheading">Author</p>}
                        {authors.map((a, i) => {
                            return (
                                <p key={`filter_${i}`} className="search-filter">
                                    <Checkbox name="filter-author" value={a.slug} checked={selectedAuthors.indexOf(a.slug) !== -1} label={a.name} onChange={(v) => {this.handleAuthorChange(a.slug, v);}} />
                                </p>
                            );
                        })}
                    </div>
                    <div className="col-8 col-small-9">
                        {resultsCount > 0 && <p className="search-heading">
                            {resultsCount} results found
                            {results.length < resultsCount && <span>{` `}(showing {results.length})</span>}
                        </p>}
                        {resultsCount === 0 && hasSearched && <p>Sorry, we couldn't find any articles matching your query.</p>}
                        {results.length > 0 && <List articles={results} showSummary showTags />}
                        {isSearching && <div className="spinner-container"><div className="spinner-wrapper"><Spinner isContinuous /></div></div>}
                        <div className="load-more-wrapper" style={showLoadMore ? {} : {display: 'none'}}><Button variant="secondary" onClick={this.props.fetchMoreSearchResults}>Load More</Button></div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
