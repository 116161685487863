import API from '../lib/api';
import {fetchSearchResults, setKeywords} from './Search';

import {LIMIT, DEFAULT_CATEGORY} from "../constants";
const SET_LIST = "ARTICLES/SET_LIST";
const SET_IS_FETCHING_LIST = "ARTICLES/SET_IS_FETCHING_LIST";
const SET_ARTICLE = "ARTICLES/SET_ARTICLE";
const SET_IS_FETCHING_ARTICLE = "ARTICLES/SET_IS_FETCHING_ARTICLE";
const SET_AUTHOR = "ARTICLES/SET_AUTHOR";
const SET_IS_FETCHING_AUTHOR = "ARTICLES/SET_IS_FETCHING_AUTHOR";
const SET_SIDEBAR_RECENT = "ARTICLES/SET_SIDEBAR_RECENT";
const SET_SIDEBAR_MOSTREAD = "ARTICLES/SET_SIDEBAR_MOSTREAD";

const defaultState = {
    list: [],
    listCount: 0,
    sidebarRecent: [],
    sidebarMostread: [],
    article: {},
    author: {},
    isFetchingList: false,
    isFetchingArticle: false,
    isFetchingAuthor: false,
};

export function fetchList(category, query, page, limit) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_LIST, payload: true});
        let _query = query || {};
        let _sort = _query.sort && _query.sort === 'mostread' ? 'mostread' : false;
        let _articletype = _query.articletype || _query.articleType || false;
        let _category = category || DEFAULT_CATEGORY;
        let _tags = _query.tags || false;
        let _page = page || 1;
        let _limit = limit || LIMIT;

        return new Promise((resolve, reject) => {
            return API.get(
                `/1/feed/category/${_category}?page=${_page}&limit=${_limit}${_sort ? '&sort=' + _sort : ''}${_articletype ? '&articleType=' + _articletype : ''}${_tags ? '&tags=' + _tags : ''}`,
            ).then((response) => {
                let _list = response.data.data;
                if (_page > 1) {
                    let currentResults = getState().articles.list;
                    _list = currentResults.concat(response.data.data);
                }
                dispatch({type: SET_LIST, payload: {list: _list, count: response.data.count}});
                dispatch({type: SET_IS_FETCHING_LIST, payload: false});
                resolve();
            }).catch((error) => {
                dispatch({type: SET_LIST, payload: {list: [{error: error.response}], count: 0}});
                dispatch({type: SET_IS_FETCHING_LIST, payload: false});
                let _s = error.response.status;
                if (_s === 404) {
                    // category slug doesn't exist, show search form
                    let words = _category.replace(/[^A-Za-z0-9\s]/gi, ' ');
                    dispatch(setKeywords(words));
                    dispatch(fetchSearchResults(1, words)).then(() => {resolve({status: _s});});
                } else {
                    resolve({status: _s});
                }
            });
        });

    };
}

export function clearList() {
    return (dispatch, getState) => {
        dispatch({type: SET_LIST, payload: {list: [], count: 0}});
    }
}

export function fetchMore(category, query) {
    return (dispatch, getState) => {
        let total = getState().articles.list.length;
        let nextPage = Math.ceil(total / LIMIT) + 1;
        dispatch(fetchList(category, query, nextPage));
    }
}

export function fetchSidebarList(sort) {
    return (dispatch, getState) => {
        let _sort = sort === 'mostread' ? 'mostread' : false;
        let _category = DEFAULT_CATEGORY;
        let _limit = 5;

        return new Promise((resolve, reject) => {
            return API.get(
                `/1/feed/category/${_category}?page=1&limit=${_limit}${_sort ? '&sort=' + _sort : ''}`,
            ).then((response) => {
                if (sort === 'mostread') {
                    dispatch({type: SET_SIDEBAR_MOSTREAD, payload: response.data.data});
                } else {
                    dispatch({type: SET_SIDEBAR_RECENT, payload: response.data.data});
                }
                resolve();
            }).catch((error) => {
                resolve({error});
            });
        });

    }
}

export function fetchArticle(slug, isPreview) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_ARTICLE, payload: true});
        dispatch({type: SET_ARTICLE, payload: {}});

        let uri = isPreview ? `/1/feed/article/preview/${slug}` : `/1/feed/article/${slug}`

        return new Promise((resolve, reject) => {
            return API.get(
                uri,
            ).then((res) => {
                dispatch({type: SET_ARTICLE, payload: res.data});
                dispatch({type: SET_IS_FETCHING_ARTICLE, payload: false});
                if (!isPreview) { dispatch(updateReadCount(res.data.id)); }
                resolve();
            }).catch((error) => {
                // console.log("errorstatus", error.response.status);
                let _s = error.response.status;
                dispatch({type: SET_ARTICLE, payload: {status: _s, id: _s, slug: slug}});
                dispatch({type: SET_IS_FETCHING_ARTICLE, payload: false});
                if (_s === 404) {
                    // article slug doesn't exist, show search form
                    let words = slug.replace(/[^A-Za-z0-9\s]/gi, ' ');
                    dispatch(setKeywords(words));
                    dispatch(fetchSearchResults(1, words)).then(() => {resolve({status: _s});});
                } else {
                    resolve({status: _s});
                }
            });
        });

    }
}

export function updateReadCount(id) {
    return (dispatch, getState) => {
        API.post(
            `/1/feed/${id}/read`
        );
    }
}

export function fetchAuthor(slug) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_AUTHOR, payload: true});

        return new Promise((resolve, reject) => {
            return API.get(
                `/1/author/${slug}`,
            ).then((res) => {
                dispatch({type: SET_AUTHOR, payload: res.data});
                dispatch({type: SET_IS_FETCHING_AUTHOR, payload: false});
                resolve();
            }).catch((error) => {
                dispatch({type: SET_AUTHOR, payload: {status: error.response.status}});
                dispatch({type: SET_IS_FETCHING_AUTHOR, payload: false});
                resolve({status: error.response.status});
            });
        });

    }
}

export function articlesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_SIDEBAR_RECENT:
        return Object.assign({}, state, {
            sidebarRecent: action.payload
        });
        case SET_SIDEBAR_MOSTREAD:
        return Object.assign({}, state, {
            sidebarMostread: action.payload
        });
        case SET_LIST:
        return Object.assign({}, state, {
            list: action.payload.list,
            listCount: action.payload.count
        });
        case SET_IS_FETCHING_LIST:
        return Object.assign({}, state, {
            isFetchingList: action.payload
        });
        case SET_ARTICLE:
        return Object.assign({}, state, {
            article: action.payload
        });
        case SET_IS_FETCHING_ARTICLE:
        return Object.assign({}, state, {
            isFetchingArticle: action.payload
        });
        case SET_AUTHOR:
        return Object.assign({}, state, {
            author: action.payload
        });
        case SET_IS_FETCHING_AUTHOR:
        return Object.assign({}, state, {
            isFetchingAuthor: action.payload
        });
        default:
        return state;
    }
}
