import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M9.2,10.1 L7,10.1 L7,12.1 L9.2,12.1 L9.2,18 L11.8,18 L11.8,12.1 L14.4,12.1 L14.4,10.1 L11.8,10.1 L11.8,9.3 C11.8,8.7 12.4,7.5 13.3,7.5 L14.4,7.5 L14.4,5.5 L12.1,5.5 C9,5.9 9.2,10.1 9.2,10.1 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M19.1,20.6 L14.2,20.6 L14.2,25 L19.1,25 L19.1,38 L24.9,38 L24.9,25 L30.7,25 L30.7,20.7 L24.9,20.7 L24.9,19 C24.9,17.7 26.3,15 28.3,15 L30.7,15 L30.7,10.7 L25.6,10.7 C18.5,11.5 19.1,20.6 19.1,20.6 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
