import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M16.8,8.2 C17.4,8.3 18,8.3 18.6,8.1 C17.9,8.5 17.3,8.9 16.7,9.5 C17,13.1 14.5,16.3 11,16.9 C9,17 7.1,16.5 5.5,15.4 C5.5,15.4 8,16.1 9.3,14.6 C9.3,14.6 7,14.8 6.4,13 C7,13 7.5,12.9 8,12.8 C6.8,12.6 5.9,11.5 5.9,10.2 C6.3,10.4 6.7,10.5 7.1,10.5 C7.1,10.5 5.3,8.9 6.4,7 C6.4,7 8.8,9.9 11.8,9.8 C11.4,8.4 12.3,7 13.6,6.7 C14.5,6.5 15.4,6.7 16.1,7.4 C16.7,7.3 17.3,7 17.8,6.6 C17.7,7.2 17.4,7.8 16.8,8.2 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M34.5,16.7 C34.5,16.7 37.7,17.1 38.6,16.5 C37.1,17.3 35.6,18.3 34.4,19.5 C34.4,19.5 35.6,32.8 21.7,36 C21.7,36 14.5,36.6 9.4,32.7 C9.4,32.7 14.9,34.2 17.9,30.8 C17.9,30.8 12.8,31.3 11.5,27.3 C12.7,27.3 13.9,27.1 15.1,26.8 C15.1,26.8 10.3,25.9 10.4,21.1 C11.2,21.6 12.1,21.8 13.1,21.8 C13.1,21.8 9.1,18.2 11.6,14 C11.6,14 16.9,20.5 23.5,20.1 C22.7,17.1 24.5,14 27.6,13.2 C29.6,12.7 31.6,13.3 33,14.7 C34.4,14.4 35.7,13.8 36.9,12.8 C36.8,13 36.9,14.8 34.5,16.7 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
