import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchAuthor } from '../redux/Articles';
import Layout from '../Layout';
import Spinner from '../audi-ui-components/Spinner';
import List from '../components/List';
import SubscribeForm from '../components/SubscribeForm';
import SocialLink from '../components/SocialLink';
import Section from '../components/Section';
import Media from '../components/Media';
import NotFound from './NotFound';
import { Helmet } from "react-helmet";

const mapStateToProps = state => {
    return {
        author: state.articles.author,
        isFetchingAuthor: state.articles.isFetchingAuthor
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchAuthor: (slug) => {dispatch(fetchAuthor(slug));}
    }
}

class Author extends React.Component {

    componentDidMount() {
        if (!this.props.author || this.props.author === null || this.props.author.slug !== this.props.match.params.slug) {
            this.props.fetchAuthor(this.props.match.params.slug);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.props.fetchAuthor(this.props.match.params.slug);
        }
    }

    render() {
        const {author, isFetchingAuthor} = this.props;
        if (author.status && author.status > 300) {
          return (<NotFound status={author.status} />);
        }
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="page-content page-content--author container-outer">

                <Helmet>
                    {author.title && <title>{`${author.title} | Audi Magazine Australia`}</title>}
                    <meta name="description" content={author.seoMetaDescription} />
                </Helmet>

                <hr className="fullbleed-below-medium" />

                {isFetchingAuthor && <div className="spinner-wrapper"><Spinner isContinuous /></div>}

                {author && author.id && !isFetchingAuthor && <div>
                    <div className="title-wrapper">
                        <h1 className="page-title padded">{author.title}</h1>
                        {author.subTitle && <div className="subtitle" dangerouslySetInnerHTML={{__html: author.subTitle}}></div>}
                    </div>
                    <div className="row">
                        <div className="col-12 col-small-auto author__img-col">
                            <Media type="Image" {...author.profileImage} useFullImg />
                            {author.socialLinks && <ul className="social-list">
                                {author.socialLinks.map((link, i) => {
                                    return (<li key={`social_${i}`}><SocialLink link={link} showUrl /></li>);
                                })}
                            </ul>}
                        </div>
                        <div className="col-12 col-small-auto author__bio-col">
                            {author.sections.length > 0 && author.sections.map((section, i) => {
                                return (<Section section={section} key={section.id} className={classnames({'first': i === 0})} />);
                            })}
                        </div>
                    </div>
                    {author.relatedArticles && author.relatedArticles.length > 0 && <hr className="separator" />}
                    {author.relatedArticles && author.relatedArticles.length > 0 && <div className="section indent related">
                        <p className="aui-headline-4 related--title">Related Stories from {author.title}</p>
                        <List row articles={author.relatedArticles} showCategory showDate columnClass="col-12 col-xsmall-6 col-medium-3" />
                    </div>}
                </div>}

                <hr className="separator" />

                <div className="section indent">
                    <div className="row">
                        <div className="col-auto">
                            <SubscribeForm />
                        </div>
                    </div>
                </div>

            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Author);
