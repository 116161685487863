import React from 'react';

export default (_props) => {
    const { className, size, large, small, ...props } = _props;

    // small icon
    if ((size && size.toLowerCase() === 'small') || small) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
                <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                    <circle cx="18.6" cy="19.5" r="3.7"/>
                    <circle cx="18.6" cy="4.7" r="3.7"/>
                    <circle cx="5.4" cy="12.1" r="3.7"/>
                    <line x1="8.5" y1="10.1" x2="15.5" y2="6.7"/>
                    <line x1="15.5" y1="17.5" x2="8.5" y2="14.1"/>
                </g>
            </svg>
        );
    }

    // large icon
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
            <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                <circle cx="36.4" cy="37.9" r="7"/>
                <circle cx="36.4" cy="10.1" r="7"/>
                <circle cx="11.6" cy="24" r="7"/>
                <line x1="17.5" y1="20.2" x2="30.5" y2="13.8"/>
                <line x1="30.5" y1="34.1" x2="17.5" y2="27.7"/>
            </g>
        </svg>
    );
};
