export const getVimeoIDFromURL = (src) => {
    if (!src) return false;
    const regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    const match = src.match(regExp);
    if (!match || !match[2]) return false;
    return match[2];
}

export const getYoutubeIDFromURL = (src) => {
    if (!src) return false;
    const url = src.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] === undefined) return false;
    return url[2].split(/[^0-9a-z_\-]/i)[0];
}
