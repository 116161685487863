import React from 'react';
import { Helmet } from "react-helmet";
import Layout from '../Layout';
import SubscribeForm from '../components/SubscribeForm';

class Subscribe extends React.Component {

    render() {
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="page-content page-content--subscribe container-outer">
                <Helmet>
                    <title>Subscribe | Audi Magazine Australia</title>
                </Helmet>

                <SubscribeForm />

            </div>
            </Layout>
        );
    }
}

export default Subscribe;
