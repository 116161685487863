import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M19.7 9.5L23.1 9.5 17.2 19.5"/>
          <path d="M17 7.5L20.2 7.5 17.2 19.5"/>
          <path d="M0.5 9.5L0.5 19.5 15.5 19.5 15.5 4.5 5.5 4.5z"/>
          <path d="M0.5,9.5 L5.5,9.5 L5.5,4.5 M3,15.5 L13,15.5 M3,12.5 L13,12.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M2.5 17.5L2.5 37.5 34.5 37.5 34.5 9.5 10.5 9.5z"/>
        <path d="M39.2 18.5L45.1 18.5 34.5 37.5"/>
        <path d="M34.5 14.5L40.2 14.5 34.5 37.5"/>
        <path d="M10.5 9.5L10.5 17.5 2.5 17.5"/>
        <path d="M7,30.5 L30,30.5"/>
        <path d="M7,24.5 L30,24.5"/>
      </g>
    </svg>
  );
};
