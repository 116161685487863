import React from 'react';
import classnames from 'classnames';
import Media from '../../components/Media';
import { getVimeoIDFromURL, getYoutubeIDFromURL } from '../../lib/helpers';
import _find from 'lodash/find';

class ArticleTitle extends React.Component {
    
    render() {
        const {article} = this.props;
        const {title, subTitle, heroImage, articleType} = article || {};
        if (articleType === 'video') {
            if (article.videoType === 'HTML 5') {
                return (
                    <div className="section first video-wrapper--background">
                        <Media type="Video" url={article.html5Video} />
                    </div>
                );
            }
            let videoId = ""; let videoSrc = "";
            if (article.videoType === 'Youtube') {
                videoId = getYoutubeIDFromURL(article.youtubeUrl);
                videoSrc = `https://www.youtube.com/embed/${videoId}?controls=1&autoplay=1&showinfo=0&rel=0&loop=0`;
            }
            if (article.videoType === 'Vimeo') {
                videoId = getVimeoIDFromURL(article.vimeoUrl);
                videoSrc = `https://player.vimeo.com/video/${videoId}?autoplay=1&badge=0&byline=0&color=000000&portrait=0&title=0`;
            }
            if (article.videoType === 'Facebook') {
                videoSrc = article.facebookUrl;
            }
            return (
                <div className="section first video-wrapper--background">
                    <div className="video-wrapper--width">
                        <div className="video-wrapper--height">
                            <iframe src={videoSrc} frameBorder="0" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            );
        }
        if (articleType === 'gallery') {
            var mainGallery = _find(article.sections, {name: "sectionGallery"});
            return (
                <div>
                    {mainGallery && <Section section={mainGallery} />}
                    <h1 className="page-title">{title}</h1>
                    <h2 className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
                </div>
            );
        }
        if (heroImage) {
            let _colorClass = heroImage.textColor === '#000000' ? 'black' : 'white';
            return (<div className="section first outdent hero-image" style={{color: heroImage.textColor ? heroImage.textColor : '#ffffff'}}>
                <Media {...heroImage} type="Image" />
                <div className={classnames("text", _colorClass)}>
                    <h1 className="page-title">{title}</h1>
                    <h2 className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
                </div>
            </div>);
        } else {
            return (<div className="section first">
                <h1 className="page-title">{title}</h1>
                <h2 className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
            </div>);
        }
    }
}

export default ArticleTitle;
