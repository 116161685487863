import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M0.5,4.5 L22.5,4.5 C23.1,4.5 23.5,4.9 23.5,5.5 L23.5,19.5 L1.5,19.5 C0.9,19.5 0.5,19.1 0.5,18.5 L0.5,4.5 Z"/>
          <path strokeLinejoin="round" d="M9.5 7L16.1 11.4 9.5 16z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M2.5,9.5 L42.5,9.5 C44.2,9.5 45.5,10.8 45.5,12.5 L45.5,37.5 L5.5,37.5 C3.8,37.5 2.5,36.2 2.5,34.5 C2.5,34.5 2.5,9.5 2.5,9.5 Z"/>
        <path strokeLinejoin="round" d="M19.5 15.4L31.4 23.4 19.5 31.6z"/>
      </g>
    </svg>
  );
};
