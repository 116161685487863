import React from 'react';
import Layout from '../Layout';

class Maintenance extends React.Component {

    render() {
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="container-outer">
                <h1 className="aui-headline-2 mb-3">Audi Magazine is currently undergoing maintenance</h1>
                <p className="mb-5">We will be back up shortly</p>
                <ul className="juicer-feed" data-feed-id="audiaustralia-1ba67d8d-c201-417a-a769-8f407a6ebfc0" data-columns="4" data-per="9"></ul>
            </div>
            </Layout>
        );
    }
}

export default Maintenance;
