import React from 'react';
import { connect } from 'react-redux';
import { fetchSearchResults, setKeywords } from '../redux/Search';
import Layout from '../Layout';
import SearchForm from '../components/SearchForm';
import Sidebar from '../components/Sidebar';
import { Helmet } from "react-helmet";

const mapDispatchToProps = dispatch => {
    return {
        fetchSearchResults: (page, keywords) => {dispatch(fetchSearchResults(page, keywords));},
        setKeywords: (words) => {dispatch(setKeywords(words));}
    }
}

class NotFound extends React.Component {

    componentDidMount() {
        const {match} = this.props;
        if (match && match.params) {
            let words = match.params["0"].replace(/[^A-Za-z0-9\s]/gi, ' ').replace("e tron", "e-tron");
            this.props.setKeywords(words);
            this.props.fetchSearchResults(1, words);
        }
    }

    render() {
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="container-outer">
                <Helmet>
                    <link rel="canonical" href="https://audimagazine.com.au" />
                </Helmet>
                <div className="row">
                    <div className="col-small">
                        <div className="not-found-page">
                            <hr className="fullbleed-below-small" />
                            <p className="aui-headline-2 page-title padded">{this.props.status && <b>{this.props.status}</b>} Sorry we couldn't find that page</p>
                            <p>You can try and find your article below.</p>
                            <p>&nbsp;</p>
                            <SearchForm />
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
            </Layout>
        );
    }
}

export default connect(null, mapDispatchToProps)(NotFound);
