import React from 'react';
import { connect } from 'react-redux';
import Layout from '../Layout';
import SocialLink from '../components/SocialLink';

const mapStateToProps = state => {
    return {
        footer: state.ui.footer || {}
    }
}

class SocialWall extends React.Component {

    render() {
        const {footer} = this.props;
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="container-outer">
                <div className="row">
                    <div className="col-medium-4 social-side">
                        <h1 className="aui-headline-2">Social Wall</h1>
                        <ul className="social-list">
                            {footer.social && footer.social.map((item, i) => {
                                return (<li key={`social_${i}`}><SocialLink link={item} showUrl /></li>);
                            })}
                        </ul>
                    </div>
                    <div className="col-medium-8 social-main">
                        <ul className="juicer-feed" data-feed-id="audiaustralia-1ba67d8d-c201-417a-a769-8f407a6ebfc0" data-columns="3" data-per="9"></ul>
                    </div>
                </div>
            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, null)(SocialWall);
