import {
  PATH_CATEGORY,
  PATH_ARTICLE,
  PATH_PAGE,
  PATH_AUTHOR,
  PATH_SOCIAL,
  PATH_SUBSCRIBE
} from './constants';

// Pages
import HomePage from './pages/HomePage';
import Article from './pages/Article';
import Page from './pages/Page';
import Author from './pages/Author';
import FilterPage from './pages/FilterPage';
import SocialWall from './pages/SocialWall';
import Subscribe from './pages/Subscribe';

// redux
import { fetchHomePage, fetchHomePageList } from './redux/HomePage';
import { fetchList, fetchArticle, fetchAuthor } from './redux/Articles';
import { fetchPage } from './redux/Pages';

const routeConfig = [
    {
        path: "/",
        exact: true,
        component: HomePage,
        loadData: (dispatch, matchParams, query) => {
            return Promise.all([
                dispatch(fetchHomePage()),
                dispatch(fetchHomePageList())
            ]);
        }
    },
    {
        path: `${PATH_CATEGORY}/:category`,
        component: FilterPage,
        loadData: (dispatch, matchParams, query) => {
            return dispatch(fetchList(matchParams.category, query));
        }
    },
    {
        path: `${PATH_ARTICLE}/:slug`,
        component: Article,
        loadData: (dispatch, matchParams, query) => {
            return dispatch(fetchArticle(matchParams.slug, query.hasOwnProperty('isPreview')));
        }
    },
    {
        path: `${PATH_PAGE}/:slug`,
        component: Page,
        loadData: (dispatch, matchParams, query) => {
            return dispatch(fetchPage(matchParams.slug));
        }
    },
    {
        path: `${PATH_AUTHOR}/:slug`,
        component: Author,
        loadData: (dispatch, matchParams, query) => {
            return dispatch(fetchAuthor(matchParams.slug));
        }
    },
    {
        path: PATH_SOCIAL,
        component: SocialWall
    },
    {
        path: PATH_SUBSCRIBE,
        component: Subscribe
    }
];

export default routeConfig;
