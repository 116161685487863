import * as React from "react";
import { VideoSlide } from "./VideoSlide.js";
export const defaultVideoProps = {
    controls: true,
    playsInline: true,
};
export const Video = ({ augment }) => {
    augment(({ render: { slide: renderSlide, ...restRender }, video: originalVideo, ...restProps }) => ({
        render: {
            slide: (slide, offset, rect) => {
                if (slide && slide.type === "video") {
                    return React.createElement(VideoSlide, { slide: slide, offset: offset });
                }
                return renderSlide === null || renderSlide === void 0 ? void 0 : renderSlide(slide, offset, rect);
            },
            ...restRender,
        },
        video: {
            ...defaultVideoProps,
            ...originalVideo,
        },
        ...restProps,
    }));
};
