import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../audi-ui-components/Button';
import IconCancel from '../audi-ui-components/icons/Cancel';

class NavPanel extends React.Component {

    render() {
        const hasCloseBtn = typeof this.props.close === 'function';
        return (
            <div className={classnames('panel', this.props.className, `anchor-${this.props.anchor}`, {'active': this.props.isActive, 'has-close-button': hasCloseBtn})}>
                {hasCloseBtn && <Button variant="icon" icon={<IconCancel small />} onClick={this.props.close} className="panel-close">close</Button>}
                <div className="vertical-gutter">
                    <div className="panel-inner">
                        <div className="container-outer">
                            <div className="panel-content">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NavPanel.propTypes = {
    anchor: PropTypes.oneOf(['left', 'bottom']),
    close: PropTypes.func,
    isActive: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node
}

NavPanel.defaultProps = {
    anchor: 'left',
    className: ''
}

export default NavPanel;
