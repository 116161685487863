import API from '../lib/api';
import cookie from '../lib/cookie';
import _remove from 'lodash/remove';

const SET_CATEGORIES = "UI/SET_CATEGORIES";
const SET_FOOTER = "UI/SET_FOOTER";
const SET_IS_SUBSCRIBING = "UI/SET_IS_SUBSCRIBING";
const SET_IS_SUBSCRIBED = "UI/SET_IS_SUBSCRIBED";

const defaultState = {
    categories: [],
    footer: {},
    isSubscribing: false,
    isSubscribed: false,
};

export function fetchCategories() {
    return (dispatch, getState) => {
        if (getState().ui.categories.length > 1) { return; }

        return new Promise((resolve, reject) => {
            API.get(
                `/1/service/categories`,
            ).then((res) => {
                let _data = res.data.map((c) => { return {label: c.name, slug: c.slug, href: `/category/${c.slug}`}; });
                dispatch({type: SET_CATEGORIES, payload: _data});
                resolve();
            }).catch((error) => {
                console.error(`${process.env.RAZZLE_API_HOST}/1/service/categories`, error);
                resolve({error});
            });
        });

    };
}

export function fetchFooterNav() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            API.get(
                `/1/service/navigation/footer`,
            ).then((res) => {
                let _contact = _remove(res.data.items, function (o) {return o.hasOwnProperty('contact');});
                let _social = _remove(res.data.items, function (o) {return o.name && o.name === "Social";});
                let _footer = {
                    navigation: res.data.items,
                    contact: _contact[0].contact,
                    social: _social[0].items
                };
                if (_footer.contact && _footer.contact.phone) {
                    _footer.contact.phoneNumber = _footer.contact.phone.replace(/\D/g,'');
                }
                dispatch({type: SET_FOOTER, payload: _footer});
                resolve();
            }).catch((error) => {
                console.error(`${process.env.RAZZLE_API_HOST}/1/service/navigation/footer`, error);
                resolve({error});
            });
        });

    };
}

export function subscribe(email, successCallback, errorCallback) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_SUBSCRIBING, payload: true});

        return API.post(
            `/1/service/subscribe`,
            {emailAddress: email}
        ).then(() => {
            dispatch({type: SET_IS_SUBSCRIBING, payload: false});
            dispatch({type: SET_IS_SUBSCRIBED, payload: true});
            cookie.setItem('subscribed', 'true', Infinity, '/');
            if (window && window.dataLayer) { window.dataLayer.push({'event': 'subscribeNow'}); }
            if (typeof successCallback === 'function') {successCallback();}
        }).catch((error) => {
            dispatch({type: SET_IS_SUBSCRIBING, payload: false});
            if (typeof errorCallback === 'function') {errorCallback(error);}
        });

    }
}

export function uiReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CATEGORIES:
        return Object.assign({}, state, {
            categories: action.payload
        });
        case SET_FOOTER:
        return Object.assign({}, state, {
            footer: action.payload
        });
        case SET_IS_SUBSCRIBING:
        return Object.assign({}, state, {
            isSubscribing: action.payload
        });
        case SET_IS_SUBSCRIBED:
        return Object.assign({}, state, {
            isSubscribed: action.payload
        });
        default:
        return state;
    }
}
