import React from 'react';

export default (_props) => {
    const { className, size, large, small, ...props } = _props;

    // small icon
    if ((size && size.toLowerCase() === 'small') || small) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
                <circle stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" cx="11.5" cy="11.5" r="11"/>
                <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M18.5,16.5H5.8c-0.7,0-1.3-0.6-1.3-1.3V6.5h12.6c0.7,0,1.3,0.6,1.3,1.3V16.5z"/>
                    <path d="M6.8,9.8l4.7,3.7l4.7-3.7"/>
                </g>
            </svg>
        );
    }

    // large icon
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
            <circle stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" cx="23.5" cy="23.5" r="22"/>
            <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M37.6,34.2H10.7c-0.7,0-1.3-0.6-1.3-1.3v-19h26.9c0.7,0,1.3,0.6,1.3,1.3V34.2z"/>
                <path d="M12.4,19.4l11.1,8.5l11.1-8.5"/>
            </g>
        </svg>
    );
};
