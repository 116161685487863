import React from 'react';

export default () => {
    return (
        <svg className="audi-magazine-logo" viewBox="0 0 213 30" width="213" height="30">
            <g>
                <path d="M97,24v-8.8c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5c-0.4,0.5-0.7,1-1.1,1.5c-0.4,0.5-0.7,1-1.1,1.5l-4,5.7h-2.6l-4-5.7 c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c0,0.5,0,1,0,1.5 c0,0.5,0,1,0,1.5V24h-3.6V7.3h4.1l5.4,7.7c0.3,0.4,0.6,0.8,0.8,1.2s0.6,0.8,0.8,1.2c0.3-0.4,0.6-0.8,0.9-1.2 c0.3-0.4,0.6-0.8,0.9-1.2l5.4-7.7h3.9V24H97z"/>
                <path d="M114.6,24l-0.2-1.2c-0.3,0.2-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.3,0.3s-1,0.1-1.5,0.1 c-0.8,0-1.5-0.1-2.2-0.2c-0.6-0.2-1.2-0.4-1.6-0.7c-0.4-0.3-0.8-0.7-1-1.2s-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3 c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.2-0.7c0.5-0.2,1-0.4,1.5-0.5c0.6-0.1,1.2-0.2,1.8-0.3s1.4-0.1,2.1-0.1c0.3,0,0.6,0,0.9,0 c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0v0c0-0.4-0.1-0.8-0.2-1.1s-0.4-0.5-0.7-0.7s-0.7-0.3-1.2-0.4c-0.5-0.1-1-0.1-1.6-0.1 c-0.5,0-1,0-1.4,0.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.2,0.3s-0.7,0.2-1.1,0.4v-2.6c0.3-0.2,0.7-0.3,1.1-0.5 c0.4-0.1,0.8-0.3,1.3-0.4c0.5-0.1,0.9-0.2,1.4-0.2c0.5-0.1,1-0.1,1.6-0.1c1.1,0,2.1,0.1,2.9,0.3c0.9,0.2,1.6,0.5,2.2,0.9 c0.6,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.2V24H114.6z M114.2,19h-1.9c-0.8,0-1.5,0-2,0.1c-0.6,0.1-1,0.1-1.4,0.3 c-0.4,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.2,0,0.4,0.1,0.5s0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.7,0.3 c0.3,0.1,0.6,0.1,1.1,0.1c0.4,0,0.9,0,1.2-0.1s0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.8-0.4V19z"/>
                <path d="M132.5,22.4c-0.3,0.2-0.6,0.4-1,0.6s-0.7,0.3-1.2,0.5s-0.8,0.2-1.3,0.3c-0.4,0.1-0.9,0.1-1.4,0.1c-1.1,0-2-0.1-2.8-0.4 c-0.8-0.3-1.5-0.7-2.1-1.2c-0.6-0.5-1-1.2-1.3-1.9c-0.3-0.8-0.5-1.6-0.5-2.5c0-0.9,0.2-1.8,0.5-2.5s0.8-1.4,1.4-1.9 s1.3-0.9,2.2-1.2s1.9-0.4,3-0.4c0.5,0,1,0,1.4,0.1s0.9,0.2,1.3,0.3c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.2,0.7,0.4,0.9,0.6l0.4-1h2.9 v11.1c0,1.1-0.2,2-0.5,2.7c-0.4,0.8-0.9,1.4-1.6,1.9s-1.5,0.9-2.5,1.1c-1,0.2-2.1,0.3-3.4,0.3c-0.6,0-1.2,0-1.7,0 c-0.5,0-1-0.1-1.5-0.1c-0.5-0.1-0.9-0.1-1.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.3v-2.9c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.2,1.2,0.2 c0.4,0.1,0.9,0.1,1.4,0.1c0.5,0,1,0.1,1.5,0.1c0.8,0,1.6-0.1,2.2-0.2c0.6-0.1,1.1-0.3,1.5-0.5c0.4-0.2,0.7-0.5,0.9-0.9 c0.2-0.4,0.3-0.8,0.3-1.4V22.4z M132.5,15.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.7-0.1-1-0.2 s-0.8-0.1-1.2-0.1c-0.6,0-1.1,0.1-1.6,0.2s-0.9,0.3-1.2,0.6c-0.3,0.3-0.6,0.6-0.7,1c-0.2,0.4-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4 c0.2,0.4,0.4,0.7,0.7,1c0.3,0.3,0.7,0.5,1.2,0.6c0.5,0.1,1,0.2,1.6,0.2c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.1,1-0.2 c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.9-0.4V15.5z"/>
                <path d="M150,24l-0.2-1.2c-0.3,0.2-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.4,0.3c-0.5,0.1-1,0.1-1.5,0.1 c-0.8,0-1.5-0.1-2.2-0.2c-0.6-0.2-1.2-0.4-1.6-0.7c-0.4-0.3-0.8-0.7-1-1.2s-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3 c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.2-0.7c0.5-0.2,1-0.4,1.5-0.5c0.6-0.1,1.2-0.2,1.8-0.3c0.7-0.1,1.4-0.1,2.1-0.1 c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0v0c0-0.4-0.1-0.8-0.2-1.1s-0.4-0.5-0.7-0.7s-0.7-0.3-1.2-0.4 c-0.5-0.1-1-0.1-1.6-0.1c-0.5,0-1,0-1.4,0.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.7,0.2-1.1,0.4v-2.6 c0.3-0.2,0.7-0.3,1.1-0.5c0.4-0.1,0.8-0.3,1.3-0.4c0.5-0.1,0.9-0.2,1.4-0.2s1-0.1,1.6-0.1c1.1,0,2.1,0.1,2.9,0.3s1.6,0.5,2.2,0.9 c0.6,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.2V24H150z M149.6,19h-1.9c-0.8,0-1.5,0-2,0.1c-0.6,0.1-1,0.1-1.4,0.3 c-0.4,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.7,0.3 c0.3,0.1,0.6,0.1,1.1,0.1c0.4,0,0.9,0,1.2-0.1c0.4-0.1,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.8-0.4V19z"/>
                <path d="M156.1,24V22l7.8-7.2h-7.6v-2.6h12.7v2l-7.7,7.2h8V24H156.1z"/>
                <path d="M172.7,10.4V7.3h3.6v3.2H172.7z M172.7,24V12.1h3.6V24H172.7z"/>
                <path d="M191.6,24v-6.5c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.2-0.6-0.4-1-0.5s-0.9-0.2-1.4-0.2 c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.7,0.1-1.1,0.2c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.2-0.8,0.4V24h-3.6V12.1h3.2l0.3,1.4 c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.9-0.3,1.3-0.3s1-0.1,1.5-0.1c0.5,0,1,0,1.5,0.1c0.5,0.1,1,0.2,1.4,0.3 c0.4,0.2,0.8,0.4,1.2,0.6c0.4,0.3,0.7,0.6,1,1s0.5,0.9,0.6,1.4c0.1,0.5,0.2,1.2,0.2,1.9v7H191.6z"/>
                <path d="M211.9,23.3c-0.3,0.2-0.7,0.3-1.2,0.5c-0.5,0.1-0.9,0.3-1.5,0.3c-0.5,0.1-1.1,0.2-1.6,0.2c-0.6,0.1-1.1,0.1-1.6,0.1 c-0.8,0-1.6-0.1-2.3-0.2s-1.3-0.3-1.9-0.6c-0.6-0.3-1.1-0.6-1.5-1s-0.8-0.8-1-1.3c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.5-0.2-1.1-0.2-1.7 c0-0.6,0.1-1.1,0.2-1.6c0.1-0.5,0.3-1,0.6-1.5s0.6-0.9,1-1.3c0.4-0.4,0.9-0.7,1.4-1c0.5-0.3,1.1-0.5,1.8-0.7s1.4-0.2,2.3-0.2 c0.8,0,1.6,0.1,2.3,0.2c0.7,0.1,1.3,0.3,1.8,0.6c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.7,0.8,1,1.2c0.3,0.5,0.4,0.9,0.6,1.5 c0.1,0.5,0.2,1.1,0.2,1.6c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2h-10.8c0,0.4,0.2,0.8,0.3,1.1 c0.2,0.3,0.5,0.6,0.8,0.8s0.8,0.4,1.3,0.5c0.5,0.1,1.1,0.2,1.9,0.2c0.5,0,1,0,1.5-0.1c0.5,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.2,1.3-0.3 c0.4-0.1,0.8-0.2,1.1-0.4V23.3z M209.2,16.8c-0.1-0.4-0.2-0.8-0.4-1.1c-0.2-0.3-0.5-0.6-0.8-0.8s-0.7-0.3-1.1-0.4 c-0.4-0.1-0.8-0.1-1.3-0.1s-0.9,0-1.3,0.1c-0.4,0.1-0.7,0.2-1,0.4c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.4,1.1H209.2z"/>
            </g>
            <g>
                <path style={{fill:'#f50537'}} d="M18.7,24l-1.8-3.5H7.3L5.5,24H1.4l8.8-16.8h3.9L22.9,24H18.7z M13.4,13.5c-0.2-0.4-0.4-0.9-0.7-1.3 c-0.2-0.4-0.4-0.9-0.7-1.3c-0.2,0.4-0.4,0.9-0.7,1.3c-0.2,0.4-0.4,0.9-0.7,1.3l-2.1,4.1h6.8L13.4,13.5z"/>
                <path style={{fill:'#f50537'}} d="M35.7,24l-0.2-1.3c-0.3,0.2-0.6,0.5-0.9,0.7c-0.4,0.2-0.7,0.4-1.2,0.5c-0.4,0.1-0.9,0.3-1.3,0.3 c-0.5,0.1-0.9,0.1-1.4,0.1c-0.5,0-1,0-1.5-0.1c-0.5-0.1-0.9-0.2-1.4-0.3s-0.8-0.4-1.2-0.6s-0.7-0.6-0.9-1s-0.5-0.8-0.6-1.4 c-0.1-0.5-0.2-1.2-0.2-1.9v-7h3.6v6.5c0,0.5,0.1,0.9,0.2,1.3s0.3,0.7,0.6,0.9c0.2,0.2,0.6,0.4,1,0.5c0.4,0.1,0.9,0.2,1.4,0.2 c0.4,0,0.7,0,1.1-0.1c0.3,0,0.6-0.1,1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4v-8.5h3.6V24H35.7z"/>
                <path style={{fill:'#f50537'}} d="M54.4,24l-0.2-1.3c-0.3,0.3-0.6,0.5-1,0.7s-0.8,0.4-1.2,0.5s-0.9,0.3-1.4,0.3 c-0.5,0.1-1,0.1-1.5,0.1c-1.1,0-2-0.1-2.8-0.4c-0.8-0.3-1.5-0.7-2.1-1.3c-0.6-0.5-1-1.2-1.3-2c-0.3-0.8-0.5-1.7-0.5-2.6 c0-1,0.2-1.9,0.5-2.6s0.8-1.4,1.4-2s1.3-1,2.2-1.2s1.9-0.4,3-0.4c0.4,0,0.9,0,1.3,0.1c0.4,0.1,0.8,0.1,1.2,0.2 c0.4,0.1,0.7,0.2,1.1,0.4s0.6,0.3,0.9,0.5V7.3h3.6V24H54.4z M54,15.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.9-0.3 s-0.7-0.1-1-0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.6,0-1.2,0.1-1.6,0.2c-0.5,0.2-0.9,0.4-1.2,0.7s-0.6,0.7-0.7,1.1 c-0.2,0.4-0.2,0.9-0.2,1.5c0,0.6,0.1,1.1,0.2,1.5c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.5,1.2,0.7c0.5,0.2,1,0.2,1.6,0.2 c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.9-0.4V15.5z"/>
                <path style={{fill:'#f50537'}} d="M62.1,10.4V7.3h3.6v3.2H62.1z M62.1,24V12.1h3.6V24H62.1z"/>
            </g>
        </svg>
    );
};
