import * as React from "react";
export const clsx = (...classes) => [...classes].filter((cls) => Boolean(cls)).join(" ");
const cssPrefix = "yarl__";
export const cssClass = (name) => `${cssPrefix}${name}`;
export const cssVar = (name) => `--${cssPrefix}${name}`;
export const composePrefix = (base, prefix) => `${base}${prefix ? `_${prefix}` : ""}`;
export const makeComposePrefix = (base) => (prefix) => composePrefix(base, prefix);
export const label = (labels, lbl) => (labels && labels[lbl] ? labels[lbl] : lbl);
export const cleanup = (...cleaners) => () => {
    cleaners.forEach((cleaner) => {
        cleaner();
    });
};
export const makeUseContext = (name, contextName, context) => () => {
    const ctx = React.useContext(context);
    if (!ctx) {
        throw new Error(`${name} must be used within a ${contextName}.Provider`);
    }
    return ctx;
};
export const hasWindow = () => typeof window !== "undefined";
export const isDefined = (x) => typeof x !== "undefined";
export const isNumber = (value) => typeof value === "number";
export const round = (value, decimals = 0) => {
    const factor = 10 ** decimals;
    return Math.round((value + Number.EPSILON) * factor) / factor;
};
export const isImageSlide = (slide) => slide && (!isDefined(slide.type) || slide.type === "image");
export const parseLengthPercentage = (input) => {
    if (typeof input === "number") {
        return { pixel: input };
    }
    if (typeof input === "string") {
        const value = parseInt(input, 10);
        return input.endsWith("%") ? { percent: value } : { pixel: value };
    }
    return { pixel: 0 };
};
export const devicePixelRatio = () => {
    return (typeof window !== "undefined" ? window === null || window === void 0 ? void 0 : window.devicePixelRatio : undefined) || 1;
};
