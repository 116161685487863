import React from 'react';
import Lightbox from "../../components/Lightbox";
import { Video } from "../../components/Lightbox/plugins/video/Video";
import { Thumbnails } from "../../components/Lightbox/plugins/thumbnails/Thumbnails";
import { Captions } from "../../components/Lightbox/plugins/captions/Captions";
import Zoom from "../../components/Lightbox/plugins/zoom";
import "../../components/Lightbox/styles.css";
import "../../components/Lightbox/plugins/thumbnails/thumbnails.css";
import "../../components/Lightbox/plugins/captions/captions.css";
import _findIndex from 'lodash/findIndex';

class ArticleLightbox extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            mediaList: []
        }
    }
    
    componentDidMount() {
        const {sections, heroImage} = this.props;
        let mediaList = [];
        if (heroImage) {
            mediaList.push({
                id: "hero",
                src: heroImage.url
            })
        }
        for (let i=0; i<sections.length; i++) {
            let section = sections[i];
            if (section.name === "sectionGallery" || section.name === "sectionImage") {
                for (let n=0; n<section.content.length; n++) {
                    let item = section.content[n];
                    if (item.media) {
                        if (item.media.type === "Image") {
                            let slide = {
                                id: item.media.id,
                                src: item.media.url,
                                alt: item.media.altText
                            };
                            if (item.media.credit || item.media.caption) {
                                let desc = "" + item.media.caption;
                                if (item.media.credit && item.media.caption) {
                                    desc += "&nbsp;&nbsp;|&nbsp;&nbsp;";
                                }
                                desc += item.media.credit;
                                slide.description = desc;
                            }
                            mediaList.push(slide);
                        }
                        if (item.media.type === "Video") {
                            mediaList.push({
                                id: item.media.id,
                                type: "video",
                                sources: [{src: item.media.url, type: "video/mp4"}]
                            });
                        }
                    }
                } // end for loop
            }
        }
        // console.log(mediaList);
        this.setState({mediaList: mediaList});
    }
    
    render() {
        const { mediaList } = this.state;
        if (!mediaList || mediaList.length === 0) {
            return null;
        }
        let activeIdx = 0;
        if (this.props.activeId) {
            activeIdx = _findIndex(mediaList, {id: this.props.activeId});
        }
        return (
            <Lightbox
                open={this.props.isActive}
                close={this.props.close}
                index={activeIdx}
                slides={mediaList}
                plugins={[Thumbnails, Captions, Video, Zoom]}
            />
        );
    }
}

export default ArticleLightbox;
