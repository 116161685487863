import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M16.1,9.6 L17.4,9.6 C17.4,10 17.4,10.5 17.4,10.9 C17.8,10.9 18.3,10.9 18.7,10.9 L18.7,12.2 C18.3,12.2 17.8,12.2 17.4,12.2 C17.4,12.6 17.4,13.1 17.4,13.5 L16.1,13.5 C16.1,13.1 16.1,12.6 16.1,12.2 L14.8,12.2 L14.8,10.9 C15.2,10.9 15.7,10.9 16.1,10.9 C16.1,10.4 16.1,10 16.1,9.6 Z M4.5,11.3 C4.6,9 6.7,6.9 9,7 C10.1,6.9 11.2,7.4 12,8.1 C11.6,8.5 11.3,8.9 10.9,9.3 C9.9,8.6 8.4,8.4 7.4,9.2 C5.9,10.2 5.9,12.6 7.3,13.7 C8.7,14.9 11.3,14.3 11.6,12.4 C10.7,12.4 9.9,12.4 9,12.4 C9,11.9 9,11.4 9,10.9 C10.4,10.9 11.9,10.9 13.3,10.9 C13.4,12.1 13.2,13.4 12.5,14.4 C11.4,16 9.1,16.4 7.4,15.8 C5.6,15 4.4,13.2 4.5,11.3 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M33.6,19.4 L36.3,19.4 C36.3,20.3 36.3,21.2 36.3,22.1 C37.2,22.1 38.1,22.1 39,22.1 L39,24.8 C38.1,24.8 37.2,24.8 36.3,24.8 C36.3,25.7 36.3,26.6 36.3,27.5 L33.6,27.5 C33.6,26.6 33.6,25.7 33.6,24.8 C32.7,24.8 31.8,24.8 30.9,24.8 L30.9,22.1 C31.8,22.1 32.7,22.1 33.6,22.1 C33.6,21.2 33.6,20.3 33.6,19.4 Z M9.1,23 C9.2,18.1 13.7,13.8 18.6,14 C20.9,13.9 23.1,14.9 24.9,16.3 C24.1,17.2 23.3,18 22.5,18.8 C20.3,17.3 17.3,16.9 15.1,18.6 C12,20.7 11.9,25.8 14.8,28.1 C17.7,30.7 23.1,29.4 23.9,25.4 C22.1,25.4 20.3,25.4 18.5,25.3 C18.5,24.2 18.5,23.1 18.5,22.1 C21.5,22.1 24.5,22.1 27.6,22.1 C27.8,24.6 27.4,27.3 25.9,29.4 C23.5,32.7 18.8,33.7 15.1,32.3 C11.4,30.9 8.8,27 9.1,23 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
