export const APPLICATION_CONTAINER_ID = 'AppContainer';

// --------------
// Named routes
// --------------
export const PATH_HOME = '/';
export const PATH_ARTICLE = '/article';
export const PATH_AUTHOR = '/author';
export const PATH_PAGE = '/page';
export const PATH_CATEGORY = '/category';
export const PATH_SOCIAL = '/socialwall';
export const PATH_SUBSCRIBE = '/subscribe';

export const LIMIT = 10;
export const DEFAULT_CATEGORY = 'all-articles';

export const MODELS = [
    ['Q4 e-tron', 'Q6 e-tron', 'Q8 e-tron', 'e-tron GT'],
    ['A1', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8'],
    ['Q2', 'Q3', 'Q5', 'Q7', 'Q8'],
    ['S', 'RS', 'TT', 'R8']
];

// --------------
// Feature toggle
// --------------
export const FEATURE_ADS = false;
export const FEATURE_SOCIAL = true;
export const FEATURE_SUBSCRIBE = true;

export const MAINTENANCE = false;
export const MAINTENANCE_SF = false;
