import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import routes from './routes';
import { MAINTENANCE } from './constants';
import './styles/main.scss';

const App = () => (
    <Switch>
        {MAINTENANCE && <Route path="/maintenance"><Maintenance /></Route>}
        {MAINTENANCE && <Route path="*"><Redirect to="/maintenance" /></Route>}
        {routes.map(route => {
            let k = route.path;
            if (Array.isArray(route.path)) {
                k = route.path[0];
            }
            k = k.replace(/[^A-Z0-9]/ig, "");
            return (
                <Route key={k} {...route} />
            );
        })}

        {/*<Route path="*" component={NotFound} />*/}
        <Route path="*" render={({ staticContext }) => {
          if (staticContext) { staticContext.status = 404; }
          return <NotFound status={404} />
        }} />
    </Switch>
);

export default App;
