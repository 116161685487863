import React from 'react';
import classnames from 'classnames';
import IconSearch from '../audi-ui-components/icons/Search';
import IconVideo from '../audi-ui-components/icons/Video';
import IconEditorial from '../audi-ui-components/icons/Editorial';
import IconNews from '../audi-ui-components/icons/News';
import IconCar from '../audi-ui-components/icons/Car';
import IconUserSubscribe from './IconUserSubscribe';
import {
    FEATURE_SUBSCRIBE
} from '../constants';

class MobileFooter extends React.Component {

    render() {
        const {activePanel, query, matchParams} = this.props;
        return (
            <footer id="mobile-footer" className="d-large-none">
              <div className="container-outer">
                <div className="row">
                  {FEATURE_SUBSCRIBE && <div className="col-auto">
                    <a onClick={(e) => {this.props.onPanelToggle('subscribe', e);}} className={classnames('mobile-footer-link', {'active': activePanel === 'subscribe'})}>
                      <span className="audiicon-responsive"><IconUserSubscribe large /><IconUserSubscribe small /></span>
                      <span className="label">Subscribe</span>
                    </a>
                  </div>}
                  <div className="col-auto">
                    <a onClick={(e) => {e.preventDefault(); this.props.onFilterVideo();}} className={classnames('mobile-footer-link', {'active': query.articletype === 'video'})}>
                      <span className="audiicon-responsive"><IconVideo large /><IconVideo small /></span>
                      <span className="label">Videos</span>
                    </a>
                  </div>
                  <div className="col-auto">
                    <a onClick={(e) => {this.props.onPanelToggle('models', e);}} className={classnames('mobile-footer-link', {'active': activePanel === 'models'})}>
                      <span className="audiicon-responsive"><IconCar large /><IconCar small /></span>
                      <span className="label">Models</span>
                    </a>
                  </div>
                  {query.sort !== 'mostread' && <div className="col-auto">
                    <a className={classnames('mobile-footer-link', {'active': query.sort === 'mostread' && matchParams.category})} onClick={(e) => { this.props.onFilterSort('mostread', e); }}>
                      <span className="audiicon-responsive"><IconNews large /><IconNews small /></span>
                      <span className="label">Most Read</span>
                    </a>
                  </div>}
                  {query.sort === 'mostread' && <div className="col-auto">
                    <a className="mobile-footer-link" onClick={(e) => { this.props.onFilterSort('date', e); }}>
                      <span className="audiicon-responsive"><IconEditorial large /><IconEditorial small /></span>
                      <span className="label">Recent</span>
                    </a>
                  </div>}
                  <div className="col-auto">
                    <a onClick={(e) => {this.props.onPanelToggle('search', e);}} className={classnames('mobile-footer-link', {'active': activePanel === 'search'})}>
                      <span className="audiicon-responsive"><IconSearch large /><IconSearch small /></span>
                      <span className="label">Search</span>
                    </a>
                  </div>
                </div>
              </div>
            </footer>
        );
    }
}

export default MobileFooter;
