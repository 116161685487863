import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { fetchHomePage, fetchHomePageList, fetchMoreHomePageList } from '../redux/HomePage';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import Sidebar from '../components/Sidebar';
import Spinner from '../audi-ui-components/Spinner';
import IconPlay from '../audi-ui-components/icons/Play';
import Carousel from '../components/AudiMagazineCarousel';
import List from '../components/List';
import moment from 'moment';
import _debounce from 'lodash/debounce';
import {
    PATH_CATEGORY,
    PATH_ARTICLE,
    FEATURE_ADS,
    DEFAULT_CATEGORY,
    LIMIT
} from '../constants';

const mapStateToProps = state => {
    return {
        hpCarousel: state.homePage.hpCarousel || [],
        hpFeatures: state.homePage.hpFeatures || [],
        hpList: state.homePage.hpList || [],
        hpListCount: state.homePage.hpListCount || 0,
        socialMediaTags: state.homePage.socialMediaTags,
        isFetchingHomePage: state.homePage.isFetchingHomePage,
        isFetchingHpList: state.homePage.isFetchingHpList
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchHomePage: () => {dispatch(fetchHomePage());},
        fetchHomePageList: () => {dispatch(fetchHomePageList());},
        fetchMoreHomePageList: () => {dispatch(fetchMoreHomePageList());}
    }
}

class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this._debouncedScroll = _debounce(this.handleScroll, 200);
        this._carouselTimeout = null;
        this._isMounted = false;
        this.state = {
            carouselImagesLoaded: [],
            autoplay: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.hpCarousel.length < 1 || this.props.hpFeatures.length < 1) {
            this.props.fetchHomePage();
        }
        if (this.props.hpList.length < 1) {
            this.props.fetchHomePageList();
        }
        window.addEventListener('scroll', this._debouncedScroll, false);
        this._carouselTimeout = setTimeout(() => { this.setState({autoplay: true}); this._carouselTimeout = null; }, 8000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("scroll", this._debouncedScroll, false);
        if (this._carouselTimeout !== null) {
            clearTimeout(this._carouselTimeout);
        }
    }

    handleScroll = () => {
        const {hpList, hpListCount, isFetchingHpList} = this.props;
        if (!isFetchingHpList && hpList.length > 0 && hpList.length < hpListCount) {
            if (this._loadMoreEl.getBoundingClientRect().top <= window.innerHeight) {
                this.props.fetchMoreHomePageList();
            }
        }
    }

    handleCarouselImgLoad = (img) => {
        if (this._isMounted) {
            let images = [...this.state.carouselImagesLoaded];
            images.push(img);
            this.setState({carouselImagesLoaded: images});
            if (images.length === this.props.hpCarousel.length) {
                if (this._carouselTimeout !== null) {
                    clearTimeout(this._carouselTimeout);
                }
                this._carouselTimeout = setTimeout(() => { this.setState({autoplay: true}); this._carouselTimeout = null; }, 2000);
            }
        }
    }

    renderSlide = (article, className, hasImgLoad, isDummySlide) => {
        if (typeof article !== 'undefined' && !!article.tileImage) {
            var x = article.tileImage.x ? article.tileImage.x : '50%';
            var y = article.tileImage.y ? article.tileImage.y : '50%';
            var colorHex = article.tileImage.textColor ? article.tileImage.textColor.toLowerCase() : '#ffffff';
            var colorName = colorHex === '#000000' ? 'black' : 'white';
            return (
                <Link key={`slide_${article.id}`} className={classnames("tile", `${className}`)} to={`${PATH_ARTICLE}/${article.slug}`} style={{color: colorHex}}>
                    {hasImgLoad && <img className="bg-image-load" alt="" src={article.tileImage.url} onLoad={() => {this.handleCarouselImgLoad(article.tileImage.url);}} />}
                    <div className="tile__image-wrapper">
                        <div className="tile__image" style={{backgroundImage: `url(${article.tileImage.url})`, backgroundPosition: `${x} ${y}`}}></div>
                        {article.articleType === 'video' && <IconPlay large />}
                    </div>
                    <div className={`tile__text ${colorName}`}>
                        <h2 className="aui-headline-5 tile__title">{article.title}</h2>
                        <div className="tile__summary" dangerouslySetInnerHTML={{__html: article.subTitle}} />
                    </div>
                </Link>
            );
        }
    }

    render() {
        const {hpCarousel, hpFeatures, hpList, hpListCount, socialMediaTags, isFetchingHomePage, isFetchingHpList} = this.props;
        var ogTitle = ""; var ogDesc = ""; var ogUrl = ""; var ogImage = "";
        if (socialMediaTags) {
            ogTitle = socialMediaTags.title;
            ogDesc = socialMediaTags.description;
            ogUrl = socialMediaTags.url;
            ogImage = socialMediaTags.image;
        }
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="container-outer">

                <Helmet>
                    <meta name="google-site-verification" content="FqFAuBGDF9jPZiUF9L0gD_gwSrGc_pDTNfuteGRDmxA" />
                    <meta property="og:title" content={ogTitle} />
                    <meta property="og:description" content={ogDesc} />
                    <meta property="og:url" content={ogUrl} />
                    <meta property="og:image" content={ogImage} />
                    <meta property="og:type" content="website" />
                </Helmet>

                <div className="row">
                    <div className="col-auto main-column">

                        <div className="hp-carousel">
                            {isFetchingHomePage && <div className="spinner-wrapper"><Spinner isContinuous /></div>}
                            {!isFetchingHomePage && hpCarousel.length > 0 && <div className="slider-wrapper">
                                <Carousel
                                    autoplay={this.state.autoplay}
                                    heightMode="max"
                                    titles={hpCarousel.map((item) => item.title)}
                                    colors={hpCarousel.map((item) => (item.tileImage && item.tileImage.textColor) ? item.tileImage.textColor : '')}
                                >
                                    {hpCarousel.map((slide) => {
                                        return (this.renderSlide(slide, 'carousel-slide', true, false));
                                    })}
                                </Carousel>
                            </div>}
                        </div>

                        <hr className="mb-4 mb-medium-5" />

                        <div className="hp-features d-none d-small-block mb-4 mb-medium-5">
                            <p className="aui-headline-5 mb-4">Highlights</p>
                            <div className="row">
                                {hpFeatures.length > 0 && <div className="col-medium-8">
                                    {this.renderSlide(hpFeatures[0], 'hp-features__main', false, false)}
                                </div>}
                                {hpFeatures.length > 1 && <div className="col-medium-4">
                                    <List articles={hpFeatures.slice(1)} className="hp-features__list" showCategory showDate />
                                </div>}
                            </div>
                        </div>

                        <div className="hp-features d-small-none mb-4 mb-medium-5">
                            <p className="aui-headline-5 mb-4">Highlights</p>
                            {hpFeatures.length > 0 && <List articles={hpFeatures} tiles className="hp-features__list" showCategory showDate />}
                        </div>

                        {FEATURE_ADS && <div style={{width: '728px', height: '90px', background: '#ccc', color: '#fff', margin: '0 auto 40px'}} className="d-none d-medium-block">728 x 90 Leaderboard</div>}

                        <hr className="mb-4 mb-medium-5" />

                        <p className="aui-headline-5 mb-4">Latest</p>
                        <List articles={hpList} tiles />

                        {isFetchingHpList && hpList.length > 0 && <div className="spinner-container"><div className="spinner-wrapper"><Spinner isContinuous /></div></div>}

                        <div ref={(el) => this._loadMoreEl = el}></div>
                    </div>
                    <Sidebar />
                </div>
            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
