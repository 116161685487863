import API from '../lib/api';

const SET_PAGE = "PAGES/SET_PAGE";
const SET_IS_FETCHING_PAGE = "PAGES/SET_IS_FETCHING_PAGE";

const defaultState = {
    page: {},
    isFetchingPage: false,
};

export function fetchPage(slug) {
    return (dispatch, getState) => {
        dispatch({type: SET_IS_FETCHING_PAGE, payload: true});

        return new Promise((resolve, reject) => {
            return API.get(
                `/1/service/page/${slug}`,
            ).then((res) => {
                dispatch({type: SET_PAGE, payload: res.data});
                dispatch({type: SET_IS_FETCHING_PAGE, payload: false});
                resolve();
            }).catch((error) => {
                dispatch({type: SET_PAGE, payload: {status: error.response.status}});
                dispatch({type: SET_IS_FETCHING_PAGE, payload: false});
                resolve({status: error.response.status});
            });
        });

    }
}

export function pagesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_PAGE:
        return Object.assign({}, state, {
            page: action.payload
        });
        case SET_IS_FETCHING_PAGE:
        return Object.assign({}, state, {
            isFetchingPage: action.payload
        });
        default:
        return state;
    }
}
