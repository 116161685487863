import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from 'nuka-carousel';
import ArrowBack from '../audi-ui-components/icons/Back';
import ArrowForward from '../audi-ui-components/icons/Forward';

class AudiMagazineCarousel extends React.Component {

    renderCarouselDots = ({goToSlide, nextSlide, slideCount, currentSlide}) => {
        const {titles, colors} = this.props;
        let nextIndex = false;
        if (titles && titles.length > 0) {
            nextIndex = currentSlide + 1;
            if (nextIndex >= slideCount) {
                nextIndex = false;
            }
        }
        let _count = [];
        for (let i=0; i<slideCount; i++) {
            _count.push('slide');
        }
        return (
            <div className="slider-dots" style={{color: colors[currentSlide]}}>
                {_count.map((item, i) => {
                    return (
                        <button key={`dot_${i}`}
                            type="button"
                            className={classnames({'active': i === currentSlide})}
                            onClick={() => {goToSlide(i);}}
                        >
                            &bull;
                        </button>
                    );
                })}
                {nextIndex && <span className="slider-dots__next" onClick={nextSlide}>Next: {titles[nextIndex]}</span>}
            </div>
        );
    }

    render() {
        const {colors} = this.props;
        return (
            <Carousel
                slidesToShow={this.props.slidesToShow}
                autoplay={this.props.autoplay}
                wrapAround
                renderCenterLeftControls={({previousSlide, currentSlide}) => (
                    <button onClick={previousSlide} style={{color: colors[currentSlide]}}><ArrowBack large /></button>
                )}
                renderCenterRightControls={({nextSlide, currentSlide}) => (
                    <button onClick={nextSlide} style={{color: colors[currentSlide]}}><ArrowForward large /></button>
                )}
                renderBottomCenterControls={this.renderCarouselDots}
            >
                {this.props.children}
            </Carousel>
        );
    }
}

AudiMagazineCarousel.propTypes = {
    slidesToShow: PropTypes.number,
    autoplay: PropTypes.bool,
    heightMode: PropTypes.string,
    titles: PropTypes.array,
    colors: PropTypes.array,
    children: PropTypes.node
}

AudiMagazineCarousel.defaultProps = {
    slidesToShow: 1,
    heightMode: null,
    colors: []
}

export default AudiMagazineCarousel;
