import API from '../lib/api';
import _filter from 'lodash/filter';

import {LIMIT} from "../constants";
const SET_KEYWORDS = "SEARCH/SET_KEYWORDS";
const SET_RESULTS = "SEARCH/SET_RESULTS";
const SET_FACETS = "SEARCH/SET_FACETS";
const CLEAR_RESULTS = "SEARCH/CLEAR_RESULTS";
const SET_IS_SEARCHING = "SEARCH/SET_IS_SEARCHING";
const SET_SELECTED_CATEGORIES = "SEARCH/SET_SELECTED_CATEGORIES";
const SET_SELECTED_AUTHORS = "SEARCH/SET_SELECTED_AUTHORS";

const defaultState = {
    keywords: '',
    keywordsChanged: false,
    results: [],
    resultsCount: 0,
    categories: [],
    authors: [],
    selectedCategories: [],
    selectedAuthors: [],
    isSearching: false,
    hasSearched: false
};

export function fetchSearchResults (page, keywords, categories, authors) {
    return (dispatch, getState) => {
        const words = keywords || getState().search.keywords;
        if (words.length < 2) {
            return dispatch({type: CLEAR_RESULTS});
        }

        dispatch({type: SET_IS_SEARCHING, payload: true});
        let _page = page || 1;
        if (getState().search.keywordsChanged) {
            dispatch({type: CLEAR_RESULTS});
            _page = 1;
        }

        var url = `/1/search/searchresults?page=${_page}&limit=${LIMIT}&keyword=${words}`;
        var _categories = categories || getState().search.selectedCategories;
        if (_categories.length && _categories.length > 0) { url += `&category=${_categories.join(",")}`; }
        var _authors = authors || getState().search.selectedAuthors;
        if (_authors.length && _authors.length > 0) { url += `&author=${_authors.join(",")}`; }

        return new Promise((resolve, reject) => {
            API.get(
              url
            ).then((response) => {
                let _results = response.data.data;
                if (_page > 1) {
                    let currentResults = getState().search.results;
                    _results = currentResults.concat(response.data.data);
                }
                let _facets = response.data.facets || {};
                dispatch({type: SET_RESULTS, payload: {data: _results, count: response.data.filterCount}});
                dispatch({type: SET_FACETS, payload: {categories: _facets.categories, authors: _facets.authors}});
                dispatch({type: SET_IS_SEARCHING, payload: false});
                resolve();
            }).catch((error) => {
                console.error(error);
                dispatch({type: SET_IS_SEARCHING, payload: false});
                resolve({error});
            });
        });

    };
}

export function fetchMoreSearchResults() {
    return (dispatch, getState) => {
        let total = getState().search.results.length;
        let nextPage = Math.ceil(total / LIMIT) + 1;
        dispatch(fetchSearchResults(nextPage));
    };
}

export function setKeywords(words) {
    return (dispatch, getState) => {
        dispatch({type: SET_KEYWORDS, payload: words});
    }
}

export function setCategories(cats) {
    return (dispatch, getState) => {
        dispatch({type: SET_SELECTED_CATEGORIES, payload: cats});
    }
}

export function setAuthors(auths) {
    return (dispatch, getState) => {
        dispatch({type: SET_SELECTED_AUTHORS, payload: auths});
    }
}

export function searchReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_KEYWORDS:
        return Object.assign({}, state, {
            keywords: action.payload,
            keywordsChanged: true
        });
        case SET_RESULTS:
        return Object.assign({}, state, {
            results: action.payload.data,
            resultsCount: action.payload.count,
            keywordsChanged: false,
            hasSearched: true,
        });
        case SET_FACETS:
        return Object.assign({}, state, {
            categories: action.payload.categories || [],
            authors: action.payload.authors || []
        });
        case CLEAR_RESULTS:
        return Object.assign({}, state, {
            results: [],
            selectedCategories: [],
            selectedAuthors: [],
            hasSearched: false
        });
        case SET_IS_SEARCHING:
        return Object.assign({}, state, {
            isSearching: action.payload
        });
        case SET_SELECTED_CATEGORIES:
        return Object.assign({}, state, {
            selectedCategories: action.payload,
        });
        case SET_SELECTED_AUTHORS:
        return Object.assign({}, state, {
            selectedAuthors: action.payload,
        });
        default:
        return state;
    }
}
