import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { PATH_ARTICLE, PATH_CATEGORY, FEATURE_ADS } from '../constants';
import moment from 'moment';
import IconPlay from '../audi-ui-components/icons/Play';

class Tile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageLoaded: false
        }
        this._imageTimeout = null;
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const {article} = this.props;
        if (article && article.tileImage) {
            this.img = new Image();
            this.img.src = article.tileImage.url;
            this.img.onload = this.handleImgLoaded;
        }
        this._imageTimeout = setTimeout(() => { this.setState({imageLoaded: true}); this._imageTimeout = null; }, 3000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this._imageTimeout !== null) {
            clearTimeout(this._imageTimeout);
        }
    }

    handleImgLoaded = () => {
        if (this._isMounted) {
            this.setState({imageLoaded: true});
        }
    }

    render() {
        const {article, columnClass, showDate, idx} = this.props;
        var image = article.tileImage || {};
        var x = image.x ? image.x : '50%';
        var y = image.y ? image.y : '50%';
        var colorHex = image.textColor ? image.textColor.toLowerCase() : '#ffffff';
        var colorName = colorHex === '#000000' ? 'black' : 'white';
        var summary = article.summary ? article.summary : article.subTitle;
        let i = idx > 10 ? idx % 10 : idx;
        var transitionStyle = {transitionDelay: `${.05 * i}s`, WebkitTransitionDelay: `${.05 * i}s`};
        return (
            <div className={columnClass}>
                <a href={`${PATH_ARTICLE}/${article.slug}`} className={classnames("list-item tile li-enter", {'li-enter-done': this.state.imageLoaded})} style={{color: colorHex, ...transitionStyle}}>
                    <div className="tile__image-wrapper">
                        <div className="tile__image" style={{backgroundImage: `url(${image.url})`, backgroundPosition: `${x} ${y}`}}></div>
                        {article.articleType === 'video' && <IconPlay large />}
                    </div>
                    <div className={`tile__text ${colorName}`}>
                        <h2 className="aui-headline-5 tile__title">{article.title}</h2>
                        <div className="tile__summary" dangerouslySetInnerHTML={{__html: summary}} />
                        {(showDate && article.datePublished) && <div className="list-item__date">{moment(article.datePublished, "M/D/YYYY h:m:s A").fromNow()}</div>}
                    </div>
                </a>
            </div>
        );
    }
}
Tile.defaultProps = {
    article: {},
    columnClass: "col-6",
    showDate: false,
    idx: 0
}

/************************************************************************************************************/

class ListItem extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
          imageLoaded: false
      }
      this._imageTimeout = null;
      this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        const {article} = this.props;
        if (article && article.tileImage) {
            this.img = new Image();
            this.img.src = article.tileImage.url;
            this.img.onload = this.handleImgLoaded;
        }
        this._imageTimeout = setTimeout(() => { this.setState({imageLoaded: true}); this._imageTimeout = null; }, 3000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this._imageTimeout !== null) {
            clearTimeout(this._imageTimeout);
        }
    }

    handleImgLoaded = () => {
        if (this._isMounted) {
            this.setState({imageLoaded: true});
        }
    }

    render() {
        const {article, showDate, showCategory, showTags, showSummary, idx} = this.props;
        var image = article.tileImage || {};
        var x = image.x ? image.x : '50%';
        var y = image.y ? image.y : '50%';
        var colorHex = image.textColor ? image.textColor.toLowerCase() : '#ffffff';
        var colorName = colorHex === '#000000' ? 'black' : 'white';
        var summary = article.summary ? article.summary : article.subTitle;
        let i = idx > 10 ? idx % 10 : idx;
        var transitionStyle = {transitionDelay: `${.05 * i}s`, WebkitTransitionDelay: `${.05 * i}s`};
        var category = null;
        if (showCategory && article.categories) {
            category = article.categories.length === 1 ? article.categories[0] : article.categories[1];
        }
        var tags = article.tags || [];
        return (
            <li className={classnames("list-item li-enter", {"li-enter-done": this.state.imageLoaded})} style={transitionStyle}>
                <Link to={`${PATH_ARTICLE}/${article.slug}`} className="list-item__image" style={{backgroundImage: `url(${image.url})`, backgroundPosition: `${x} ${y}`, color: colorHex}}>
                    <span></span>
                    {article.articleType === 'video' && <IconPlay large />}
                </Link>
                <div className="list-item__text">
                    <h2 className="aui-headline-5 list-item__title"><Link to={`${PATH_ARTICLE}/${article.slug}`}>{article.title}</Link></h2>
                    {showSummary && <div className="list-item__summary" dangerouslySetInnerHTML={{__html: summary}} />}
                    {showCategory && <Link to={`${PATH_CATEGORY}/${category.slug}`} className="list-item__category">{category.name}</Link>}
                    {(showDate && article.datePublished) && <div className="list-item__date">{moment(article.datePublished, "M/D/YYYY h:m:s A").fromNow()}</div>}
                    {showTags && <div className="list-item__tags">{tags.map((tag, i) => <span key={`tag_${i}`} className="tag">{tag}</span>)}</div>}
                </div>
            </li>
        );
    }
}
ListItem.defaultProps = {
    article: {},
    showDate: false,
    showCategory: false,
    showTags: false,
    showSummary: false,
    idx: 0
}

/************************************************************************************************************/

class List extends React.Component {

    render() {
        var articles = this.props.articles.slice(0);
        if (FEATURE_ADS && this.props.insertLeaderboard && articles.length >= 8) {
            articles.splice(4, 0, "leaderboard");
        }

        if (this.props.tiles) {
            return (
                <div className={classnames('articles-list articles-list-tiles row', {[this.props.className]: this.props.className})}>
                    {articles.length > 0 && articles.map((article, i) => {
                        if (!article || article === null) {
                            return null;
                        }
                        if (article === 'leaderboard') {
                            return (<div className="col-12" key={`leaderboard_${i}`}>
                                <div style={{width: '728px', height: '90px', background: '#ccc', color: '#fff', margin: '0 auto 40px'}} className="d-none d-medium-block">728 x 90 Leaderboard</div>
                            </div>);
                        }
                        return (
                            <Tile key={`list-tile-${article.id}`} article={article} columnClass={this.props.columnClass} showDate={this.props.showDate} idx={i} />
                        );
                    })}
                </div>
            );
        }

        if (this.props.row) {
            return (
                <div className={classnames('row articles-list articles-list-row', {[this.props.className]: this.props.className})}>
                    {this.props.articles.map((article, i) => {
                        if (!article || article === null) {
                            return null;
                        }
                        let _cat = null;
                        if (this.props.showCategory && article.categories) {
                            _cat = article.categories.length === 1 ? article.categories[0] : article.categories[1]; // TODO: filter out all-articles
                        }
                        let _x = article.tileImage && article.tileImage.x ? article.tileImage.x : '50%'; let _y = article.tileImage && article.tileImage.y ? article.tileImage.y : '50%';
                        let _columnClass = this.props.columnClass || "col";
                        let _tc = article.tileImage && article.tileImage.textColor ? article.tileImage.textColor : '#ffffff';
                        return (
                            <div key={`li_${article.id}`} className={_columnClass}>
                                <div className="list-item">
                                    {article.tileImage &&
                                        <Link to={`${PATH_ARTICLE}/${article.slug}`} className="list-item__image" style={{backgroundImage: `url(${article.tileImage.url})`, backgroundPosition: `${_x} ${_y}`, color: _tc}}>
                                            <span></span>
                                            {article.articleType === 'video' && <IconPlay large />}
                                        </Link>
                                    }
                                    <div className="list-item__text">
                                        <h2 className="aui-headline-5 list-item__title"><Link to={`${PATH_ARTICLE}/${article.slug}`}>{article.title}</Link></h2>
                                        {this.props.showSummary && <div className="list-item__summary" dangerouslySetInnerHTML={{__html: _summary}} />}
                                        {this.props.showCategory && _cat && <Link to={`${PATH_CATEGORY}/${_cat.slug}`} className="list-item__category">{_cat.name}</Link>}
                                        {this.props.showDate && <div className="list-item__date">{moment(article.datePublished, "M/D/YYYY h:m:s A").fromNow()}</div>}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <ul className={classnames('articles-list articles-list-ul', {'small': this.props.small, [this.props.className]: this.props.className})}>
                {this.props.articles.map((article, i) => {
                    return (
                        <ListItem key={`li_${article.id}`} article={article} showSummary={this.props.showSummary} showCategory={this.props.showCategory} showDate={this.props.showDate} showTags={this.props.showTags} idx={i} />
                    );
                })}
            </ul>
        );

    }
}

List.propTypes = {
    showCategory: PropTypes.bool,
    showDate: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    tiles: PropTypes.bool,
    row: PropTypes.bool,
    small: PropTypes.bool,
    insertLeaderboard: PropTypes.bool,
    className: PropTypes.string,
    columnClass: PropTypes.string,
    articles: PropTypes.array
}

List.defaultProps = {
    articles: [],
}

export default List;
