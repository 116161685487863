import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M16.2,6 L6.8,6 C6.4,6 6,6.3 6,6.8 L6,16.2 C6,16.6 6.4,17 6.8,17 L16.2,17 C16.6,17 17,16.6 17,16.2 L17,6.8 C17,6.3 16.6,6 16.2,6 Z M9.3,15.4 L7.6,15.4 L7.6,10.2 L9.2,10.2 L9.3,15.4 Z M8.4,9.4 C7.9,9.4 7.5,9 7.5,8.5 C7.5,8 7.9,7.6 8.4,7.6 C8.9,7.6 9.3,8 9.3,8.5 C9.3,9 9,9.4 8.4,9.4 Z M15.4,15.4 L13.8,15.4 L13.8,12.8 C13.8,12.2 13.8,11.4 13,11.4 C12.2,11.4 12,12.1 12,12.7 L12,15.3 L10.4,15.3 L10.4,10.1 L12,10.1 L12,10.8 C12.2,10.4 12.8,10 13.5,10 C15.2,10 15.5,11.1 15.5,12.5 C15.4,12.5 15.4,15.4 15.4,15.4 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M33.3,12 L13.7,12 C12.8,12 12,12.7 12,13.7 L12,33.4 C12,34.3 12.8,35.1 13.7,35.1 L33.3,35.1 C34.2,35.1 35,34.4 35,33.4 L35,13.7 C35,12.7 34.2,12 33.3,12 Z M18.8,31.6 L15.4,31.6 L15.4,20.6 L18.8,20.6 L18.8,31.6 Z M17.1,19.1 C16,19.1 15.1,18.2 15.1,17.1 C15.1,16 16,15.1 17.1,15.1 C18.2,15.1 19.1,16 19.1,17.1 C19.1,18.2 18.2,19.1 17.1,19.1 Z M31.6,31.6 L28.2,31.6 L28.2,26.3 C28.2,25 28.2,23.4 26.4,23.4 C24.6,23.4 24.4,24.8 24.4,26.2 L24.4,31.6 L21,31.6 L21,20.6 L24.3,20.6 L24.3,22.1 C24.8,21.2 25.9,20.3 27.5,20.3 C31,20.3 31.6,22.6 31.6,25.5 L31.6,31.6 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
