import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import NavPanel from './NavPanel';
import NavBar from './NavBar';
import AudiMagazineLogo from './AudiMagazineLogo';
import AudiRingsLogo from './AudiRingsLogo';
import IconGrab from '../audi-ui-components/icons/Grab';
import IconCancel from '../audi-ui-components/icons/Cancel';
import IconSearch from '../audi-ui-components/icons/Search';
import IconVideo from '../audi-ui-components/icons/Video';
import IconCar from '../audi-ui-components/icons/Car';
import IconNews from '../audi-ui-components/icons/News';
import IconEditorial from '../audi-ui-components/icons/Editorial';
import IconArrowUp from '../audi-ui-components/icons/ArrowUp';
import SubscribeForm from './SubscribeForm';
import SearchForm from './SearchForm';
import {
    MAINTENANCE,
    PATH_HOME,
    PATH_CATEGORY,
    PATH_ARTICLE,
    PATH_PAGE,
    PATH_SUBSCRIBE,
    MODELS,
    FEATURE_SUBSCRIBE
} from '../constants';

class Header extends React.Component {

    render() {
        const {navUp, activePanel, categories, query, footer, matchParams, showModelFilter} = this.props;
        return (
            <header id="header" className={classnames({'up': navUp && activePanel === null})}>
              <div className="outer-wrapper">
                <div className="vertical-gutter">

                  <div className="logorow">
                    <div className="container-outer">
                      <div className="row">
                        <div className="col-3 col-large-auto logo-col" style={{zIndex: "10"}}>
                          <Link to="/" className="audi-rings-logo-link"><AudiRingsLogo /></Link>
                        </div>
                        <div className="col-6 col-large-9" style={{textAlign: 'center'}}>
                          <Link to="/" className="audi-mag-logo-link"><AudiMagazineLogo /></Link>
                        </div>
                        <div className="col-3" style={{textAlign: 'right'}}>
                          {!MAINTENANCE && <div className="d-large-none">
                            <a onClick={(e) => {this.props.onPanelToggle('mainnav', e);}} className={classnames('mainnav-toggle', {'active': activePanel === 'mainnav'})}>
                              {activePanel === 'mainnav' && <IconCancel small />}
                              {activePanel !== 'mainnav' && <IconGrab small />}
                            </a>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="desktop-nav d-none d-large-block">
                    <div className="container-outer">
                      <div className="row">
                        <div className="col-9 navbar">
                          {categories.length > 0 && <NavBar actions={categories} activeIndex={this.props.getActiveCategoryIndex()} onActionClick={this.props.onCategoryClick} autoSetWidth />}
                        </div>
                        <div className="col-3 search">
                          {!MAINTENANCE && <div>
                            <a onClick={(e) => {this.props.onPanelToggle('filter', e);}} className={classnames("aui-button aui-button--secondary filter-button", {'active': activePanel === 'filter'})}>
                              <span className="aui-button__text">Filter Articles</span>
                            </a>
                            <a onClick={(e) => {this.props.onPanelToggle('search', e);}} className={classnames("aui-button aui-button--secondary search-button", {'active': activePanel === 'search'})}>
                              <span className="aui-button__text-icon"><IconSearch small /></span>
                            </a>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>

                  <NavPanel className="d-large-none panel--mainnav" isActive={activePanel === 'mainnav'}>
                    {categories.length > 0 && <nav>
                      <ul className="categories">
                        {categories.map((c, i) => {
                          return (
                            <li key={`category_${i}`}>
                              <Link to={{pathname: `${PATH_CATEGORY}/${c.slug}`, query: query}} onClick={() => {this.props.onPanelToggle('mainnav');}}>{c.label}</Link>
                            </li>
                          );
                        })}
                      </ul>
                      <hr className="separator" />
                      {footer.contact && <ul className="mainnav-links-medium">
                        {footer.contact.web && <li><a href={footer.contact.web}>Audi.com.au</a></li>}
                        {footer.contact.phone && <li>Call us: <a href={`tel:${footer.contact.phoneNumber}`} className="phonenum"><b>{footer.contact.phone}</b></a></li>}
                      </ul>}
                      <hr className="separator" />
                      <ul className="mainnav-links-small">
                        <li><a target="_blank" href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms_and_conditions.html">Terms and Conditions</a></li>
                        <li><a target="_blank" href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/privacy_policy.html">Privacy Policy</a></li>
                      </ul>
                    </nav>}
                  </NavPanel>

                  <NavPanel className="d-large-none" isActive={activePanel === 'subscribe'}>
                    <div className="row"><div className="col-auto"><SubscribeForm /></div></div>
                  </NavPanel>

                  <NavPanel className="d-none d-large-block panel--filter" isActive={activePanel === 'filter'}>
                    <div className="row main-filter">
                      <div className="col-3">
                        <button type="button"
                          className={classnames("filter-button", {'active': query.articletype === 'video'})}
                          onClick={(e) => {this.props.onFilterVideo(); e.preventDefault();}}
                        >
                          <IconVideo large />
                          <span className="label">Videos</span>
                        </button>
                      </div>
                      <div className="col-3">
                        <button type="button"
                          className={classnames("filter-button", {'active': showModelFilter})}
                          onClick={this.props.onToggleModelFilter}
                        >
                          <IconCar large />
                          <span className="label">Models</span>
                        </button>
                      </div>
                      <div className="col-3">
                        <button type="button"
                          className={classnames("filter-button", {'active': query.sort === 'mostread' && (matchParams && matchParams.category)})}
                          onClick={(e) => {this.props.onFilterSort('mostread'); e.preventDefault();}}
                        >
                          <IconNews large />
                          <span className="label">Most Read</span>
                        </button>
                      </div>
                      <div className="col-3">
                        <button type="button"
                          className={classnames("filter-button", {'active': query.sort !== 'mostread' && (matchParams && matchParams.category)})}
                          onClick={(e) => {this.props.onFilterSort('date'); e.preventDefault();}}
                        >
                          <IconEditorial large />
                          <span className="label">Recent</span>
                        </button>
                      </div>
                    </div>
                    {showModelFilter && <div className="model-filter">
                      {MODELS.map((modelRow, i) => <p key={`modelrow_${i}`}>
                        {modelRow.map((model) => <button key={`filter_${model}`} type="button" className={classnames("aui-button", {'active': query.tags.indexOf(model) !== -1})} onClick={() => {this.props.onFilterModel(model);}}>
                          {model}
                        </button>)}
                      </p>)}
                    </div>}
                  </NavPanel>

                  <NavPanel className="panel--models d-large-none" isActive={activePanel === 'models'} anchor="bottom" close={() => {this.handlePanelToggle('models');}}>
                    {MODELS.map((modelRow, i) => <p key={`modelrow_${i}`}>
                      {modelRow.map((model) => <button key={`filter_${model}`} type="button" className={classnames("aui-button", {'active': query.tags.indexOf(model) !== -1})} onClick={() => {this.props.onFilterModel(model);}}>
                        {model}
                      </button>)}
                    </p>)}
                  </NavPanel>

                  <NavPanel className="panel--search" isActive={activePanel === 'search'} close={() => {this.props.onPanelToggle('search');}}>
                    <SearchForm closePanel={() => {this.props.onPanelToggle(null);}} isActive={activePanel === 'search'} />
                  </NavPanel>

                </div>
              </div>
            </header>
        );
    }
}

export default Header;
