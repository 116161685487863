import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchSidebarList } from '../redux/Articles';
import List from './List';
import Button from '../audi-ui-components/Button';
import { FEATURE_ADS, FEATURE_SOCIAL } from '../constants';

import {
    PATH_SOCIAL
} from '../constants';

const mapStateToProps = state => {
    return {
        sidebarRecent: state.articles.sidebarRecent || [],
        sidebarMostread: state.articles.sidebarMostread || []
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchSidebarList: (sort) => {dispatch(fetchSidebarList(sort));}
    }
}

class Sidebar extends React.Component {

    componentDidMount() {
        const {sidebarRecent, sidebarMostread} = this.props;
        if (!sidebarRecent || sidebarRecent.length < 1) {
            this.props.fetchSidebarList('recent');
        }
        if (!sidebarMostread || sidebarMostread.length < 1) {
            this.props.fetchSidebarList('mostread');
        }
    }

    render() {
        const {sidebarRecent, sidebarMostread} = this.props;
        return (
            <div className="col-large-auto">
                <div className="sidebar d-none d-large-block">
                    <hr />
                    {sidebarRecent && sidebarRecent.length > 0 && <div className="sidebar-block first">
                        <h3 className="aui-headline-5">Recent News</h3>
                        <List articles={sidebarRecent} small showDate />
                    </div>}
                    {FEATURE_ADS && <div className="sidebar-block">
                        <div style={{width: '300px', height: '250px', background: '#ccc', color: '#fff'}}>300 x 250 MREC</div>
                    </div>}
                    {sidebarMostread && sidebarMostread.length > 0 && <div className={classnames("sidebar-block", {'border': FEATURE_ADS})}>
                        <h3 className="aui-headline-5">Most Read Articles</h3>
                        <List articles={sidebarMostread} small showDate />
                    </div>}
                    {FEATURE_SOCIAL && <div className="sidebar-block">
                        <h3 className="aui-headline-5" style={{paddingBottom: '24px'}}>Audi Social Feed</h3>
                        <ul className="juicer-feed" data-feed-id="audiaustralia-1ba67d8d-c201-417a-a769-8f407a6ebfc0" data-columns="1" data-per="3" data-pages="1"></ul>
                        <p style={{textAlign: 'center'}}><Button variant="secondary" to={PATH_SOCIAL}>See All</Button></p>
                    </div>}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
