import React from 'react';

export default (_props) => {
    const { className, size, large, small, ...props } = _props;

    // small icon
    if ((size && size.toLowerCase() === 'small') || small) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
                <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path d="M16,19.3v1.6H3.1v-8c0-0.9,0.8-1.7,1.7-1.7h7.5"/>
                    <path d="M11.2,9.6H7.9c-0.7,0-1.2-0.6-1.2-1.2V5.1c0-0.7,0.6-1.2,1.2-1.2h3.2c0.7,0,1.2,0.6,1.2,1.2v3.2 C12.4,9.1,11.8,9.6,11.2,9.6z"/>
                    <circle cx="17.2" cy="13.8" r="4.3"/>
                    <g>
                        <line x1="17.2" y1="12" x2="17.2" y2="15.6"/>
                        <line x1="19" y1="13.8" x2="15.4" y2="13.8"/>
                    </g>
                </g>
            </svg>
        );
    }

    // large icon
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
            <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M27.1,36.9v2.9H3.5V25.1c0-1.7,1.4-3.1,3.1-3.1h13.7"/>
                <path d="M18.3,19h-6c-1.3,0-2.3-1-2.3-2.3v-6c0-1.3,1-2.3,2.3-2.3h6c1.3,0,2.3,1,2.3,2.3v6C20.6,18,19.6,19,18.3,19z" />
                <circle cx="34.3" cy="25.1" r="10.4"/>
                <g>
                    <line x1="34.3" y1="20.7" x2="34.3" y2="29.5"/>
                    <line x1="38.7" y1="25.1" x2="30" y2="25.1"/>
                </g>
            </g>
        </svg>
    );
};
