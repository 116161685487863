import axios from 'axios';

export default axios.create({
    baseURL: `${process.env.RAZZLE_API_HOST}`,
    headers: {
      common: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      }
    }
});
