import React from 'react';

import IconFacebook from '../audi-ui-components/icons/SystemFacebook';
import IconInstagram from '../audi-ui-components/icons/SystemInstagram';
import IconYoutube from '../audi-ui-components/icons/SystemYoutube';
import IconGooglePlus from '../audi-ui-components/icons/SystemGooglePlus';
const socialIcons = {
    "Facebook": IconFacebook,
    "Instagram": IconInstagram,
    "Youtube": IconYoutube,
    "GooglePlus": IconGooglePlus
}

class SocialLink extends React.Component {

    render() {
        const {link, showUrl} = this.props;
        let Icon = socialIcons[link.name];
        let displayUrl = link.navigationUrl.indexOf('://') === -1 ? link.navigationUrl : link.navigationUrl.substring(link.navigationUrl.indexOf('://') + 3);
        return(
            <a href={link.navigationUrl} target="_blank" className="social-link">
                <Icon small />
                {showUrl && <span className="social-link__url">{displayUrl}</span>}
            </a>
        );
    }
}

export default SocialLink;
