import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    EmailShareButton,
} from 'react-share';
import IconFacebook from '../audi-ui-components/icons/SystemFacebook';
import IconLinkedin from '../audi-ui-components/icons/SystemLinkedin';
import IconTwitter from '../audi-ui-components/icons/SystemTwitter';
import IconPinterest from '../audi-ui-components/icons/SystemPinterest';
import IconSystemMail from './IconSystemMail';
import IconShare from './IconShare';

class ShareWidget extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false
        }
    }

    toggleExpanded = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    }

    render() {
        const {shareUrl, shareTitle, media} = this.props;
        const {isExpanded} = this.state;
        return (
            <div className={classnames("share-widget", {'expanded': isExpanded})}>
                <button onClick={this.toggleExpanded} className="share-widget__expand-btn">
                    Share <IconShare small />
                </button>
                <div className="share-widget__panel">
                    <div className="share-widget__buttons">
                        <FacebookShareButton url={shareUrl} quote={shareTitle}>
                            <IconFacebook small />
                        </FacebookShareButton>

                        <LinkedinShareButton url={shareUrl} title={shareTitle}>
                            <IconLinkedin small />
                        </LinkedinShareButton>

                        <TwitterShareButton url={shareUrl} title={shareTitle}>
                            <IconTwitter small />
                        </TwitterShareButton>

                        <PinterestShareButton url={shareUrl} media={media}>
                            <IconPinterest small />
                        </PinterestShareButton>

                        <EmailShareButton url={shareUrl} subject={shareTitle}>
                            <IconSystemMail small />
                        </EmailShareButton>
                    </div>
                </div>
            </div>
        );
    }
}

export default ShareWidget;
