import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M11.8,5 C8.3,5 6.5,7.5 6.5,9.7 C6.5,11 7,12.1 8,12.6 C8.2,12.7 8.3,12.6 8.4,12.4 C8.4,12.3 8.5,11.9 8.6,11.8 C8.7,11.6 8.6,11.5 8.5,11.4 C8.1,11 7.9,10.5 7.9,9.9 C7.9,8 9.3,6.3 11.6,6.3 C13.6,6.3 14.7,7.5 14.7,9.2 C14.7,11.4 13.7,13.2 12.3,13.2 C11.5,13.2 10.9,12.6 11.1,11.8 C11.3,10.9 11.8,9.8 11.8,9.1 C11.8,8.5 11.5,8 10.8,8 C10,8 9.4,8.8 9.4,9.9 C9.4,10.6 9.6,11.1 9.6,11.1 L8.6,15.2 C8.3,16.4 8.6,17.9 8.6,18 C8.6,18.1 8.7,18.1 8.8,18 C8.9,17.9 9.8,16.7 10.1,15.6 C10.2,15.3 10.6,13.5 10.6,13.5 C10.9,14 11.6,14.4 12.4,14.4 C14.8,14.4 16.4,12.2 16.4,9.3 C16.5,7.1 14.7,5 11.8,5 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M24.2,9 C16.3,9 12.3,14.7 12.3,19.4 C12.3,22.3 13.4,24.8 15.7,25.8 C16.1,26 16.4,25.8 16.5,25.4 C16.6,25.1 16.8,24.4 16.8,24.1 C16.9,23.7 16.9,23.5 16.6,23.2 C15.9,22.4 15.5,21.4 15.5,19.9 C15.5,15.7 18.7,11.9 23.7,11.9 C28.2,11.9 30.6,14.6 30.6,18.3 C30.6,23.1 28.5,27.2 25.3,27.2 C23.6,27.2 22.3,25.8 22.7,24 C23.2,21.9 24.2,19.6 24.2,18.1 C24.2,16.7 23.5,15.6 22,15.6 C20.2,15.6 18.8,17.4 18.8,19.9 C18.8,21.5 19.3,22.5 19.3,22.5 L17.2,31.6 C16.6,34.3 17.1,37.6 17.2,37.9 C17.2,38.1 17.5,38.1 17.6,38 C17.8,37.8 19.9,35.2 20.6,32.5 C20.8,31.8 21.8,27.9 21.8,27.9 C22.4,29 24.1,30 25.9,30 C31.3,30 34.9,25.1 34.9,18.6 C34.7,13.6 30.5,9 24.2,9 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
