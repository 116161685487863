import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchPage } from '../redux/Pages';
import Layout from '../Layout';
import Spinner from '../audi-ui-components/Spinner';
import Section from '../components/Section';
import Media from '../components/Media';
import NotFound from './NotFound';
import { Helmet } from "react-helmet";

const mapStateToProps = state => {
    return {
        page: state.pages.page || {},
        isFetchingPage: state.pages.isFetchingPage
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchPage: (slug) => {dispatch(fetchPage(slug));}
    }
}

class Page extends React.Component {

    componentDidMount() {
        if (!this.props.page || this.props.page === null) {
            this.props.fetchPage(this.props.match.params.slug);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.props.fetchPage(this.props.match.params.slug);
        }
    }

    renderTitle = () => {
        const {page} = this.props;
        const {title, subTitle, heroImage} = page || {};
        if (heroImage) {
            let _colorClass = heroImage.textColor === '#000000' ? 'black' : 'white';
            return (
                <div className="section first outdent hero-image" style={{color: heroImage.textColor ? heroImage.textColor : '#ffffff'}}>
                    <Media {...heroImage} type="Image" />
                    <div className={classnames("text", _colorClass)}>
                        <h1 className="page-title">{title}</h1>
                        <h2 className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="section first">
                    <h1 className="page-title">{title}</h1>
                    <h2 className="subtitle" dangerouslySetInnerHTML={{__html: subTitle}}></h2>
                </div>
            );
        }
    }

    render() {
        const {page, isFetchingPage} = this.props;
        if (page.status && page.status > 300) {
          return (<NotFound status={page.status} />);
        }
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="page-content container-outer">

                <Helmet>
                    <title>{page.title ? page.title : ''} | Audi Magazine Australia</title>
                    {page.seoMetaDescription && <meta name="description" content={page.seoMetaDescription} />}
                </Helmet>

                {isFetchingPage && <div className="spinner-wrapper"><Spinner isContinuous /></div>}

                {page && !isFetchingPage && <div>
                    {this.renderTitle()}
                    {page.sections.map((section) => <Section section={section} key={section.id} indent />)}
                </div>}

            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);
