import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from './AudiMagazineCarousel';
import Media from './Media';
import Button from '../audi-ui-components/Button';

class Section extends React.Component {

    render() {
        const {section, className, indent} = this.props;

        if (section.name === 'sectionGallery') {
            if (section.content.length < 4) {
                let colWidth = 12 / (section.content.length);
                return (
                    <div className={classnames("section sectionGallery", className)}>
                        <div className="row">
                            {section.content.map((item, i) => {
                                if (!item.media) { return null; }
                                return (
                                    <div key={`${item.media.id}`} className={`col-small-${colWidth}`}>
                                        <Media {...item.media} openLightbox={this.props.openLightbox} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }
            if (section.content.length === 4) {
                return (
                    <div className={classnames("section sectionGallery", className)}>
                        <div className="row">
                            <div className="col-12">
                                <Media {...section.content[0].media} openLightbox={this.props.openLightbox} />
                            </div>
                        </div>
                        <div className="row">
                            {section.content.map((item, i) => {
                                if (i > 0 && item.media) {
                                    return (
                                        <div key={`${item.media.id}`} className="col-small-4">
                                            <Media {...item.media} openLightbox={this.props.openLightbox} />
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                );
            }
            if (section.content.length > 4) {
                // slideshow
                return (
                    <div className={classnames("section sectionGallery sectionGallery--carousel", className)}>
                        <div className="row">
                            <div className="col-12">
                                <Carousel heightMode="current" colors={section.content.map((item) => item.media ? item.media.textColor : "#ffffff")}>
                                    {section.content.map((item, i) => {
                                        if (!item.media) { return null; }
                                        return (
                                            <div key={`${item.media.id}`} className="slide-article-img">
                                                <Media {...item.media} openLightbox={this.props.openLightbox} />
                                            </div>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        if (section.name === 'sectionQuote') {
            return (<div className={classnames('section cms-content indent', section.name, className)}>
                <div className="row">
                    {section.content.map((item, i) => {
                        if (item.htmlText) { return (<div key={`content_${section.id}_${i}`} className="col-small" dangerouslySetInnerHTML={{__html: item.htmlText}}></div>); }
                        if (item.quote) {
                            if (item.quote.toLowerCase().indexOf('put quote here') === -1) {
                                return (
                                    <div key={`content_${section.id}_${i}`} className={classnames("col-small-5 col-large-6 position-relative", {"d-none d-medium-block": item.hideInMobileView})}>
                                        <div className="quote"><div className="quote-inner" dangerouslySetInnerHTML={{__html: item.quote}}></div></div>
                                    </div>
                                );
                            }
                        }
                    })}
                </div>
            </div>);
        }

        if (section.name === 'sectionSubtitle') {
            return (<div className={classnames('section cms-content', section.name, className)}>
                {section.content.map((item, i) => {
                    if (item.plainText) { return (<div key={`content_${section.id}_${i}`} className="col-small"><h3 className="aui-headline-3" style={{textAlign: 'center'}}>{item.plainText}</h3></div>)}
                })}
            </div>);
        }
        
        if (section.name === 'sectionCTA') {
            return (<div className={classnames('section', section.name, className, {"single": section.content.length === 1})}>
                {section.content.map((item, i) => {
                    return (
                        <div className={classnames("cta-item mb-3", {"has-media": Boolean(item.media)})} key={`content_${section.id}_${i}`}>
                            <Media {...item.media} />
                            <div className="cta-item__text">
                                <p className="aui-headline-4 mb-2"><b>{item.title}</b></p>
                                <div className="mb-3" dangerouslySetInnerHTML={{__html: item.text}} />
                                <p><Button variant="primary" theme={item.media ? "light" : ""} href={item.ctaLink} target="_blank" rel="noreferrer">{item.ctaText}</Button></p>
                            </div>
                        </div>
                    );
                })}
            </div>);
        }

        let _indent = false;
        if (indent) {
            for (let i=0; i<section.content.length; i++) {
                if (section.content[i].hasOwnProperty('htmlText')) {
                    _indent = true;
                    break;
                }
            }
        }
        let contentLength = "single";
        if (section.content.length > 1) { contentLength = "double"; }
        if (section.content.length > 2) { contentLength = "multi"; }
        return (<div className={classnames('section cms-content', section.name, className, contentLength, {'indent': _indent})}>
            <div className="row">
                {section.content.map((item, i) => {
                    if (item.plainText) { return (<div key={`content_${section.id}_${i}`} className="col-small">{item.plainText}</div>)}
                    if (item.htmlText) { return (<div key={`content_${section.id}_${i}`} className="col-small" dangerouslySetInnerHTML={{__html: item.htmlText}}></div>); }
                    if (item.quote) { return (
                        <div key={`content_${section.id}_${i}`} className="col-small">
                            <div className="quote"><div className="quote-inner" dangerouslySetInnerHTML={{__html: item.quote}}></div></div>
                        </div>
                    ); }
                    if (item.media) {
                        return (
                            <div key={`content_${section.id}_${i}`} className="col-small">
                                <Media {...item.media} openLightbox={this.props.openLightbox} />
                            </div>
                        );
                    }
                })}
            </div>
        </div>);
    }
}

Section.propTypes =  {
    section: PropTypes.object,
    indent: PropTypes.bool,
    className: PropTypes.string
}

Section.defaultProps = {
    className: ''
}

export default Section;
