import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchList, fetchMore, clearList } from '../redux/Articles';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import Sidebar from '../components/Sidebar';
import List from '../components/List';
import Spinner from '../audi-ui-components/Spinner';
import IconCancel from '../audi-ui-components/icons/Cancel';
import _debounce from 'lodash/debounce';
import _difference from 'lodash/difference';
import { FEATURE_ADS } from '../constants';
import NotFound from './NotFound';
import queryString from 'query-string';

const mapStateToProps = state => {
    return {
        categories: state.ui.categories || [],
        list: state.articles.list || [],
        listCount: state.articles.listCount || 0,
        isFetchingList: state.articles.isFetchingList
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchList: (category, query, page, limit) => {dispatch(fetchList(category, query, page, limit));},
        fetchMore: (category, query) => {dispatch(fetchMore(category, query));},
        clearList: () => {dispatch(clearList());}
    }
}

class FilterPage extends React.Component {

    constructor(props) {
        super(props);
        this._debouncedScroll = _debounce(this.handleScroll, 200);
    }

    componentDidMount() {
        if (this.props.list.length === 0 && !this.props.isFetchingList) {
            this.props.fetchList(this.props.match.params.category, queryString.parse(this.props.location.search));
        }
        window.addEventListener('scroll', this._debouncedScroll, false);
    }

    componentDidUpdate(prevProps) {
        var prevQ = queryString.parse(prevProps.location.search) || {};
        var nextQ = queryString.parse(this.props.location.search) || {};
        if (
            this.props.match.params.category !== prevProps.match.params.category ||
            prevQ.articletype !== nextQ.articletype ||
            prevQ.sort !== nextQ.sort ||
            prevQ.tags !== nextQ.tags
        ) {
            this.props.clearList();
            this.props.fetchList(this.props.match.params.category, nextQ);
        }
    }

    componentWillUnmount() {
        this.props.clearList();
        window.removeEventListener("scroll", this._debouncedScroll, false);
    }

    handleScroll = () => {
        const {list, listCount, isFetchingList} = this.props;
        if (!isFetchingList && list.length > 0 && list.length < listCount) {
            if (this._loadMoreEl.getBoundingClientRect().top <= window.innerHeight) {
                this.handleFetchMore();
            }
        }
    }

    getActiveCategory = () => {
        const {match, categories} = this.props;
        if (match.params.category) {
            for (let i=0; i<categories.length; i++) {
                if (categories[i].slug === match.params.category) {
                    return categories[i].label;
                    break;
                }
            }
        }
        return '';
    }

    handleFetchMore = () => {
        const {match, location} = this.props;
        let q = queryString.parse(location.search) || {};
        this.props.fetchMore(match.params.category, q);
    }

    render() {
        const {location, list, listCount, isFetchingList} = this.props;

        if (list && list[0]?.error?.status > 300) {
            return (<NotFound />);
        }

        var query = queryString.parse(location.search) || {};
        var filters = [];
        if (query.sort && query.sort === 'mostread') {
            filters.push({label: 'Most Read'});
        } else {
            filters.push({label: 'Recent'});
        }
        if (query.articletype && query.articletype === 'video') {
            let newQuery = {...query};
            delete newQuery.articletype;
            filters.push({ label: 'Videos', query: newQuery });
        }
        if (query.tags) {
            let _tags = query.tags.split(',');
            for (let i=0; i<_tags.length; i++) {
                let newQuery = {...query};
                let newTags = _difference(_tags, [_tags[i]]); //[..._tags].splice(i, 1);
                newQuery.tags = newTags.join(',');
                filters.push({label: _tags[i], query: newQuery});
            }
        }
        return (
            <Layout location={this.props.location} match={this.props.match} history={this.props.history}>
            <div className="container-outer">
                <div className="row">
                    <div className="col-small">
                        <div className="filter-page">
                            <hr className="fullbleed-below-small" />
                            <h1 className="aui-headline-3 page-title padded">{this.getActiveCategory()}</h1>
                            {filters.length > 0 && <h2 className="aui-headline-5 page-title filters-list">
                                Filtered by
                                {filters.map((filter, i) => {
                                    if (filter.query) {
                                        return (<Link key={`filter_${i}`} className="filter" to={{pathname: location.pathname, search: `?${queryString.stringify(filter.query)}`}}>{filter.label}<IconCancel small /></Link>);
                                    }
                                    return (<span key={`filter_${i}`} className="filter">{filter.label}</span>);
                                })}
                            </h2>}
                            {!isFetchingList && list.length === 0 && <div><p>Sorry, we couldn't find any articles.</p>{filters.length > 2 && <p>Try removing some filters.</p>}</div>}
                            <List tiles articles={list} insertLeaderboard />
                            {isFetchingList && <div className="spinner-container"><div className="spinner-wrapper"><Spinner isContinuous /></div></div>}
                            <div ref={(el) => this._loadMoreEl = el}></div>
                            {FEATURE_ADS && <div style={{width: '728px', height: '90px', background: '#ccc', color: '#fff', margin: '0 auto 40px'}} className="d-none d-medium-block">728 x 90 Leaderboard</div>}
                        </div>
                    </div>
                    <Sidebar />
                </div>
            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);
