import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M17.9,8.9 C17.9,8.9 17.8,8 17.4,7.6 C16.9,7.1 16.4,7.1 16.1,7 C14.3,6.9 11.6,6.9 11.6,6.9 C11.6,6.9 8.9,6.9 7.1,7 C6.8,7 6.3,7 5.8,7.6 C5.3,8 5.1,8.9 5.1,8.9 C5.1,8.9 5,10 5,11 L5,12 C5,13.1 5.1,14.1 5.1,14.1 C5.1,14.1 5.2,15 5.6,15.4 C6.1,15.9 6.7,15.9 7,16 C8,16.1 11.4,16.1 11.4,16.1 C11.4,16.1 14.1,16.1 15.9,16 C16.2,16 16.7,16 17.2,15.4 C17.6,15 17.7,14.1 17.7,14.1 C17.7,14.1 18,13 18,12 L18,11 C18,10 17.9,8.9 17.9,8.9 Z M10.2,13.2 L10.2,9.5 L13.7,11.3 C13.7,11.4 10.2,13.2 10.2,13.2 Z" fill="currentColor" fillRule="nonzero"/>
          <circle stroke="currentColor" cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M37.7,17.9 C37.7,17.9 37.4,15.9 36.5,15 C35.4,13.8 34.2,13.8 33.6,13.8 C29.5,13.5 23.5,13.5 23.5,13.5 C23.5,13.5 17.4,13.5 13.4,13.8 C12.8,13.9 11.6,13.9 10.5,15 C9.6,15.9 9.3,17.9 9.3,17.9 C9.3,17.9 9,20.2 9,22.5 L9,24.7 C9,27 9.3,29.4 9.3,29.4 C9.3,29.4 9.6,31.4 10.5,32.3 C11.6,33.5 13.1,33.4 13.7,33.5 C16,33.7 23.6,33.8 23.6,33.8 C23.6,33.8 29.7,33.8 33.8,33.5 C34.4,33.4 35.6,33.4 36.7,32.3 C37.6,31.4 37.9,29.4 37.9,29.4 C37.9,29.4 38.2,27.1 38.2,24.7 L38.2,22.5 C38,20.2 37.7,17.9 37.7,17.9 Z M20.5,27.4 L20.5,19.3 L28.3,23.4 C28.3,23.4 20.5,27.4 20.5,27.4 Z" fill="currentColor" fillRule="nonzero"/>
        <circle stroke="currentColor" cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
