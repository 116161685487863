import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { fetchArticle } from '../../redux/Articles';
import Layout from '../../Layout';
import Spinner from '../../audi-ui-components/Spinner';
import Section from '../../components/Section';
import List from '../../components/List';
import SubscribeForm from '../../components/SubscribeForm';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { PATH_ARTICLE, PATH_AUTHOR, FEATURE_SUBSCRIBE } from '../../constants';
import NotFound from '../NotFound';
import queryString from 'query-string';
import ArticleTitle from './ArticleTitle';
import ArticleIntro from './ArticleIntro';
import ArticleLightbox from './ArticleLightbox';

const mapStateToProps = state => {
    return {
        article: state.articles.article,
        isFetchingArticle: state.articles.isFetchingArticle
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchArticle: (slug, isPreview) => {dispatch(fetchArticle(slug, isPreview));}
    }
}

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            lightboxOpen: false,
            lightboxActiveId: null
        }
    }

    componentDidMount() {
        const {location, match, article, fetchArticle} = this.props;
        if (!article.hasOwnProperty('id') || article.slug !== match.params.slug) {
            var q = queryString.parse(location.search);
            fetchArticle(match.params.slug, q && q.isPreview);
        } else {
            setTimeout(() => {this.setState({showContent: true});}, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.setState({showContent: false});
            var q = queryString.parse(this.props.location.search) || {};
            this.props.fetchArticle(this.props.match.params.slug, q.isPreview);
        }
        if (prevProps.isFetchingArticle && !this.props.isFetchingArticle) {
            setTimeout(() => {this.setState({showContent: true});}, 1000);
        }
    }

    render() {
        if (this.props.article.status && this.props.article.status > 300) {
            return (<NotFound status={this.props.article.status} />);
        }

        const {article, isFetchingArticle} = this.props;
        var sectionsToSkip = [];
        if (article && article.sections && article.sections.length > 0) {
            let introIdx = _findIndex(article.sections, {name: "sectionIntro"});
            if (introIdx !== -1) { sectionsToSkip.push(introIdx); }
            if (article.articleType === "gallery") {
                let galleryIdx = _findIndex(article.sections, {name: "sectionGallery"});
                if (galleryIdx !== -1) { sectionsToSkip.push(galleryIdx); }
            }
        }

        let schema = {
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": article.title
        };
        if (article.heroImage) {
            schema.image = {
                "@type": "ImageObject",
                "url": article.heroImage.url,
                "height": article.heroImage.height,
                "width": article.heroImage.width
            }
        }
        schema = JSON.stringify(schema);

        const socialMediaTags = article.socialMediaTags || {};
        var ogTitle = socialMediaTags.title || article.title;
        var ogDesc = socialMediaTags.description || article.seoMetaDescription;
        var ogUrl = socialMediaTags.url || "https://magazine.audi.com.au/article/" + article.slug;
        var ogImage = socialMediaTags.image || article.tileImage ? article.tileImage.url : "";
        var ogImageWidth = (socialMediaTags.image && socialMediaTags.imageWidth) ? socialMediaTags.imageWidth : article.tileImage ? article.tileImage.width : "";
        var ogImageHeight = (socialMediaTags.image && socialMediaTags.imageHeight) ? socialMediaTags.imageHeight : article.tileImage ? article.tileImage.height : "";

        return (
            <Layout location={this.props.location} match={this.props.match} path={this.props.location.pathname} history={this.props.history}>
            <div className="page-content page-content--article container-outer spinner-container">

                <Helmet>
                    {article.title && <title>{`${article.title} | Audi Magazine Australia`}</title>}
                    <meta name="description" content={article.seoMetaDescription} />
                    <meta property="og:title" content={ogTitle} />
                    <meta property="og:description" content={ogDesc} />
                    <meta property="og:url" content={ogUrl} />
                    <meta property="og:image" content={ogImage} />
                    <meta property="og:image:width" content={ogImageWidth} />
                    <meta property="og:image:height" content={ogImageHeight} />
                    <meta property="og:type" content="article" />
                    <script type="application/ld+json">{schema}</script>
                    {article.canonicalUrl && <link rel="canonical" href={article.canonicalUrl} />}
                </Helmet>

                <CSSTransition in={isFetchingArticle || !this.state.showContent} classNames="spinner" timeout={1000}>
                    <div className="spinner-wrapper"><Spinner isContinuous /></div>
                </CSSTransition>

                {article && article.id && !isFetchingArticle && <article>
                    <ArticleTitle article={article} />
                    <ArticleIntro article={article} />
                    {article.sections && article.sections.length - sectionsToSkip.length > 0 && <hr />}
                    {article.sections && article.sections.length > 0 && article.sections.map((section, i) => {
                        if (section && sectionsToSkip.indexOf(i) === -1) {
                            return (<Section section={section} key={section.id} indent openLightbox={(id) => { this.setState({lightboxOpen: true, lightboxActiveId: id}); }} />);
                        }
                    })}
                    <ArticleLightbox sections={article.sections} heroImage={article.heroImage} activeId={this.state.lightboxActiveId} isActive={this.state.lightboxOpen} close={() => { this.setState({lightboxOpen: false}); }} />
                </article>}

                {article && !isFetchingArticle && <div>
                    <hr />
                    <div className="section indent related">
                        <p className="aui-headline-4 related--title">Related Stories</p>
                        <List row articles={article.relatedArticles} showCategory showDate columnClass="col-12 col-xsmall-6 col-large-3" />
                    </div>
                    {FEATURE_SUBSCRIBE && <hr className="light" />}
                    {FEATURE_SUBSCRIBE && <div className="section indent subscribe">
                        <SubscribeForm />
                    </div>}
                </div>}

            </div>
            </Layout>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
