import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { fetchCategories, fetchFooterNav, subscribe } from './redux/Ui';
import cookie from './lib/cookie';
import _debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import Modal from './audi-ui-components/Modal';
import Checkbox from './audi-ui-components/Checkbox';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileFooter from './components/MobileFooter';
import SubscribeForm from './components/SubscribeForm';
import {
  MAINTENANCE,
  PATH_HOME,
  PATH_CATEGORY,
  PATH_SUBSCRIBE,
  FEATURE_SUBSCRIBE
} from './constants';

const mapStateToProps = state => {
  return {
    categories: state.ui.categories || [],
    footer: state.ui.footer || {}
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchCategories: () => {dispatch(fetchCategories());},
    fetchFooterNav: () => {dispatch(fetchFooterNav());}
  }
}

class Layout extends React.Component {

  static fetchData({ dispatch, getState }) {
    return Promise.all([
      dispatch(fetchCategories()),
      dispatch(fetchFooterNav())
    ]);
  }

  constructor(props) {
    super(props);
    this._debouncedScroll = _debounce(this.handleScroll, 200);
    this.state = {
      lastScrollY: 0,
      navUp: false,
      pageHasAutoload: false,
      footerFixed: false,
      activePanel: null,
      showModelFilter: false,
      subscribePopup: false,
      disableSubscribeCheckbox: false
    }
  }

    componentDidMount() {
      if (!MAINTENANCE) {
        const {location} = this.props;
        if (!this.props.categories || this.props.categories.length < 2) {
            this.props.fetchCategories();
        }
        if (!this.props.footer || Object.keys(this.props.footer).length === 0) {
            this.props.fetchFooterNav();
        }
        if (location && (location.pathname === PATH_HOME || location.pathname.indexOf(PATH_CATEGORY) !== -1)) {
            this.setState({pageHasAutoload: true});
        }
        window.addEventListener('scroll', this._debouncedScroll, false);
        if (FEATURE_SUBSCRIBE) {
            if (cookie.getItem('subscribed') === null && cookie.getItem('disableSubscribePopup') === null) {
                // check for edm source or subscribe page
                let query = location ? queryString.parse(location.search) : false;
                if ((query && query.utm_source) || location?.pathname === PATH_SUBSCRIBE) {
                    cookie.setItem('disableSubscribePopup', 'true', 2592000, '/');
                } else {
                    setTimeout(() => {this.setState({subscribePopup: true});}, 4000);
                }
            }
        }
      }
    }

    componentDidUpdate(prevProps) {
        const { location, match } = this.props;
        if (prevProps.match && match && prevProps.match.params.slug !== match.params.slug) {
            this.setState({activePanel: null});
            if (location && (location.pathname === PATH_HOME || location.pathname.indexOf(PATH_CATEGORY) !== -1)) {
                this.setState({pageHasAutoload: true});
            } else {
                this.setState({pageHasAutoload: false});
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._debouncedScroll, false);
    }

    handleScroll = () => {
        const { lastScrollY, pageHasAutoload } = this.state;
        var delta = 10; var navbarHeight = 130;
        var st = window.scrollY;
        // Make scroll more than delta
        if(Math.abs(lastScrollY - st) <= delta) {return;}
        // If scrolled down and past the navbar
        if (st > lastScrollY && st > navbarHeight) { // Scroll Down
            this.setState({ navUp: true });
        } else { // Scroll Up
            this.setState({ navUp: false });
        }
        this.setState({ lastScrollY: st });
        if (pageHasAutoload) {
            if ((this._mainBottomEl.getBoundingClientRect().top - 50) <= window.innerHeight) {
                this.setState({footerFixed: false});
            } else {
                this.setState({footerFixed: true});
            }
        }
    }

    handleCategoryClick = (idx) => {
        const {history, categories} = this.props;
        history.push({pathname: `/category/${categories[idx].slug}`});
    }

    getActiveCategoryIndex = () => {
        const { match, categories } = this.props;
        if (match && match.params.category) {
            for (let i=0; i<categories.length; i++) {
                if (categories[i].slug === match.params.category) {
                    return i;
                    break;
                }
            }
        }
        return null;
    }

    handlePanelToggle = (panelName, e) => {
        if (typeof e !== 'undefined') {e.preventDefault();}
        if (this.state.activePanel !== panelName) {
            this.setState({activePanel: panelName});
        } else {
            this.setState({activePanel: null});
        }
    }

    handleFilterVideo = () => {
        const {match, location} = this.props;
        let _c = (match && match.params.category) ? match.params.category : 'all-articles';
        let _q = location ? queryString.parse(location.search) : {};
        if (_q.articletype && _q.articletype === 'video') {
            delete _q.articletype;
        } else {
            _q.articletype = 'video';
        }
        this.props.history.push({
            pathname: `${PATH_CATEGORY}/${_c}`,
            search: `?${queryString.stringify(_q)}`
        });
        this.setState({activePanel: null});
    }

    handleFilterModel = (model) => {
        const {match, location} = this.props;
        let query = location ? queryString.parse(location.search) : {};
        let _c = (match && match.params.category) ? match.params.category : 'all-articles';
        let _tags = query.tags ? query.tags.split(',') : [];
        let _i = _tags.indexOf(model);
        if (_i === -1) {
            _tags.push(model);
        } else {
            _tags.splice(_i, 1);
        }
        let _q = Object.assign({}, query, {tags: _tags.join(',')});
        this.props.history.push({
            pathname: `${PATH_CATEGORY}/${_c}`,
            search: `?${queryString.stringify(_q)}`
        });
        this.setState({activePanel: null});
    }

    handleFilterSort = (sortVal) => {
        const {match, location} = this.props;
        let query = location ? queryString.parse(location.search) : {};
        let _c = (match && match.params.cateogry) ? match.params.category : 'all-articles';
        let _q = Object.assign({}, query);
        if (_q.sort && _q.sort === sortVal) {
            delete _q.sort;
        } else {
            _q.sort = sortVal;
        }
        this.props.history.push({
            pathname: `${PATH_CATEGORY}/${_c}`,
            search: `?${queryString.stringify(_q)}`
        });
        this.setState({activePanel: null});
    }

    handleSubscribePopupClose = () => {
        this.setState({subscribePopup: false});
        if (this.state.disableSubscribeCheckbox) {
            cookie.setItem('disableSubscribePopup', 'true', Infinity, '/');
        } else {
            cookie.setItem('disableSubscribePopup', 'true', 2592000, '/');
        }
    }

  render() {
    const {categories, footer, location, match} = this.props;
    let q = location ? queryString.parse(location.search) : {};
    const query = Object.assign({
      tags: [],
      articletype: null,
      sort: null
    }, q);
    const {navUp, activePanel, showModelFilter} = this.state;
    return (
      <div id="layout">

        <Helmet>
          <title>Audi Magazine Australia</title>
          <meta name="description" content="Audi Magazine brings you the latest news, products and information from the world of Audi." />
        </Helmet>

        <Header
            navUp={navUp}
            activePanel={activePanel}
            onPanelToggle={this.handlePanelToggle}
            categories={categories}
            getActiveCategoryIndex={this.getActiveCategoryIndex}
            onCategoryClick={this.handleCategoryClick}
            query={query}
            footer={footer}
            onFilterVideo={this.handleFilterVideo}
            onFilterSort={this.handleFilterSort}
            onFilterModel={this.handleFilterModel}
            showModelFilter={showModelFilter}
            onToggleModelFilter={(e) => {this.setState({showModelFilter: !showModelFilter}); e.preventDefault();}}
            matchParams={match ? match.params : {}}
        />

        <main id="main">
          <div className="outer-wrapper">
            <div className="vertical-gutter main-wrapper">
              {this.props.children}
            </div>
          </div>
          <div ref={(el) => this._mainBottomEl = el}></div>
        </main>

        <Footer footer={footer} footerFixed={this.state.footerFixed} pageHasAutoload={this.state.pageHasAutoload} />

        {!MAINTENANCE &&
            <MobileFooter
                activePanel={activePanel}
                query={query}
                matchParams={match ? match.params : {}}
                onPanelToggle={this.handlePanelToggle}
                onFilterSort={this.handleFilterSort}
                onFilterVideo={this.handleFilterVideo}
            />
        }

        <Modal isActive={this.state.subscribePopup} closeButton close={this.handleSubscribePopupClose} variant="layer" className="subscribe-popup">
          <SubscribeForm />
          <p style={{margin: "20px 0 0"}}>
            <Checkbox label="Do not show this message again." name="disableSubscribePopup" value="true" checked={this.state.disableSubscribeCheckbox} onChange={() => {this.setState({disableSubscribeCheckbox: !this.state.disableSubscribeCheckbox})}} />
          </p>
        </Modal>

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
