// Store
import { thunk } from './middleware';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

// Reducers
import {uiReducer} from './Ui';
import {articlesReducer} from './Articles';
import {pagesReducer} from './Pages';
import {homePageReducer} from './HomePage';
import {searchReducer} from './Search';

const combinedReducers = combineReducers({
    ui: uiReducer,
    articles: articlesReducer,
    pages: pagesReducer,
    homePage: homePageReducer,
    search: searchReducer
});
let devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const createAppStore = (initialState) => createStore(combinedReducers, initialState, composeEnhancers(applyMiddleware(thunk)));

// https://github.com/zalmoxisus/redux-devtools-extension
