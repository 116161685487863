import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M5,15.5 L19,15.5 L5,15.5 Z M5,12.5 L19,12.5 L5,12.5 Z M13,9.5 L19,9.5 L13,9.5 Z M13,6.5 L19,6.5 L13,6.5 Z M5.5,6.5 L10.5,6.5 L10.5,9.5 L5.5,9.5 L5.5,6.5 Z"/>
          <path d="M21.5,23.5 L3.5,23.5 C3,23.5 2.5,23.1 2.5,22.5 L2.5,0.5 L20.5,0.5 C21,0.5 21.5,0.9 21.5,1.5 L21.5,23.5 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M11,32.5 L37,32.5 L11,32.5 Z M11,28.5 L37,28.5 L11,28.5 Z M11,24.5 L37,24.5 L11,24.5 Z M23,20.5 L37,20.5 L23,20.5 Z M23,16.5 L37,16.5 L23,16.5 Z M23,12.6 L37,12.6 L23,12.6 Z M11.5,12.5 L20.5,12.5 L20.5,20.5 L11.5,20.5 L11.5,12.5 Z"/>
        <path d="M41.5,6.5 C41.5,4.8 40.2,3.5 38.5,3.5 L6.5,3.5 L6.5,40.5 C6.5,42.2 7.8,43.5 9.5,43.5 L41.5,43.5 L41.5,6.5 Z"/>
      </g>
    </svg>
  );
};
