import React from 'react';
import classnames from 'classnames';
import SocialLink from './SocialLink';
import IconArrowUp from '../audi-ui-components/icons/ArrowUp';

class Footer extends React.Component {

    render() {
        const {footer, footerFixed, pageHasAutoload} = this.props;
        return (
            <footer id="footer" className={classnames("d-none d-large-block", {'fixed': footerFixed && pageHasAutoload})}>
              <div className="outer-wrapper">
                <div className="container-outer">
                  <div className="footer-wrapper">
                    <IconArrowUp large className="footer-expand-icon" />
                    <div className="row">
                      {footer.contact && <div className="col-small">
                        <p className="footer-title">Contact Us</p>
                        <ul>
                          {footer.contact.phone && <li>Call us: <br /><a href={`tel:${footer.contact.phoneNumber}`} className="phonenum">{footer.contact.phone}</a></li>}
                          {footer.contact.web && <li><a href={footer.contact.web}>Online</a></li>}
                        </ul>
                      </div>}
                      {footer.navigation && footer.navigation.map((item, i) => {
                        return (
                          <div className="col-small" key={`footer_${i}`}>
                            <p className="footer-title">{item.name}</p>
                            <ul>
                              {item.items.map((link, n) => {
                                return (<li key={`footer_link_${n}`}><a href={link.navigationUrl} target="_blank">{link.name}</a></li>);
                              })}
                            </ul>
                          </div>
                        );
                      })}
                      {footer.social && <div className="col-small-3 col-medium-4 col-large-5 social">
                        {footer.social.map((link, n) => {
                          return (<SocialLink link={link} key={`social_${link.name}`} />);
                        })}
                      </div>}
                    </div>
                    <div className="footer-copy">
                      <span>&copy; Copyright Audi Australia. All rights reserved</span>
                      <a target="_blank" href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms-and-conditions.html">Terms and Conditions</a>
                      <a target="_blank" href="https://www.audi.com.au/au/web/en.html#layer=/au/web/en/privacy-policy.html">Privacy Policy</a>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
        );
    }
}

export default Footer;
