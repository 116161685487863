import React from 'react';
import _find from 'lodash/find';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ShareWidget from '../../components/ShareWidget';
import { PATH_AUTHOR } from '../../constants';

class ArticleIntro extends React.Component {

    render() {
        const {article} = this.props;
        const {author, datePublished, additionalContributors} = article || {};
        const section = _find(article.sections, {name: "sectionIntro"}) || {id: "intro", content: [{htmlText: ""}]};
        return (<div key={section.id} className="section indent sectionIntro">
            <div className="row">
                <div className="col-tiny-9 col-medium-6">
                    {section.content[0].htmlText && <div className="intro" dangerouslySetInnerHTML={{__html: section.content[0].htmlText}}></div>}
                    <div className="info">
                        {author && <p><Link to={`${PATH_AUTHOR}/${author.slug}`}><label>Story by</label> {author.title}</Link></p>}
                        {additionalContributors && additionalContributors.map((contrib, i) => {
                            return (<p key={`contrib_${i}`}><label>{contrib.label}</label> {contrib.contributor}</p>);
                        })}
                        <p className="date">{moment(datePublished).format("D MMMM, YYYY")}</p>
                    </div>
                </div>
                <div className="col-tiny-3 col-medium-6">
                    <ShareWidget shareUrl={typeof window !== 'undefined' ? window.location.href : ""} shareTitle={article.title} media={article.tileImage ? article.tileImage.url : ''} />
                </div>
            </div>
        </div>);
    }
}

export default ArticleIntro;
