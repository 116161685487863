import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { subscribe } from '../redux/Ui';
import Button from '../audi-ui-components/Button';
import Spinner from '../audi-ui-components/Spinner';
import { MAINTENANCE_SF } from '../constants';

const mapStateToProps = state => {
    return {
        isSubscribed: state.ui.isSubscribed || false,
        isSubscribing: state.ui.isSubscribing || false
    }
}
const mapDispatchToProps = dispatch => {
    return {
        subscribe: (email, successCallback, errorCallback) => {dispatch(subscribe(email, successCallback, errorCallback));}
    }
}

class SubscribeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            invalid: false,
            showStatement: false
        }
    }

    validateEmail = (email) => {
        return /(.+)@(.+){2,}\.(.+){2,}/.test(email);
    }

    handleSubscribe = () => {
        if (this.state.email.length > 0) {
            // input has value
            if (this.validateEmail(this.state.email)) {
                // email is valid
                this.props.subscribe(
                    this.state.email,
                    () => {
                        this.setState({email: '', invalid: false});
                        if (typeof this.props.onSubmit === "function") {
                            this.props.onSubmit();
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.setState({invalid: true});
                    }
                );

            } else {
                // email is not valid
                this.setState({invalid: true});
            }
        }
    }

    render() {
        if (MAINTENANCE_SF) {
            return (
                <div className="subscribe-form maintenance">
                    <p className="mb-3">We are undergoing website maintenance until week commencing 30 August. If you would like to subscribe to Audi Magazine email communications please:</p>
                    <ul>
                        <li>Email us at <a className="aui-textlink" href="mailto:customerassistance@audi-info.com.au">customerassistance@audi-info.com.au</a></li>
                        <li>Call us on <a className="aui-textlink" href="tel:1300557405">1300 557 405</a></li>
                    </ul>
                </div>
            );
        }
        return (
            <div className="subscribe-form spinner-container">
                {this.props.isSubscribing && <div className="spinner-wrapper"><Spinner isContinuous /></div>}
                {this.props.isSubscribed && <p className="aui-headline-4 subscribe-form-title">Thank you for subscribing!</p>}
                {!this.props.isSubscribed && <div className="row">
                    <div className="col-auto">
                        <p className="aui-headline-4 subscribe-form-title">Subscribe</p>
                        <p className="subscribe-form-copy">Want to ensure you always receive the latest news and features from Audi? Subscribe now to the Audi Magazine newsletter.</p>
                        <div className="row">
                            <div className="col-xsmall">
                                <input
                                    className="text-input"
                                    placeholder="Enter your email address"
                                    value={this.state.email || ''}
                                    onChange={(e) => {this.setState({email: e.target.value.trim(), invalid: false});}}
                                />
                            </div>
                            <div className="col-xsmall-auto">
                                <Button variant="secondary" onClick={this.handleSubscribe}>Subscribe Now</Button>
                            </div>
                        </div>
                        {this.state.invalid && <p className="aui-color-text-danger">Please enter a valid email address.</p>}
                    </div>
                </div>}
                <Button
                  variant="text"
                  className={classnames("data-collection-toggle", {'active': this.state.showStatement})}
                  onClick={() => {this.setState({showStatement: !this.state.showStatement});}}>
                  Read our Data Collection Statement here 
                </Button>
                <p className={classnames("data-collection", {'active': this.state.showStatement})}>
                    Audi Australia will collect, record and use your personal information for the purpose(s) of sending you the requested newsletter.
                    You are not required to provide your personal information, however, if you choose not to provide us with your personal information, we may not be able to fulfil the purpose(s) described above.
                    We will keep your personal information for only as long as is necessary to carry out the purpose(s) described above (unless we are required or permitted by law to hold the information for a longer period).
                    We may disclose your personal information to our service providers and to our dealership network in Australia.
                    We may also disclose your personal information to our related parties based in Australia and to our overseas service providers.
                    We may, unless you have opted out, use your personal information to market our products and services to you, to improve our products and services and to invite you to events.
                    We will act in accordance with our privacy policy which is available at <a href="http://www.audi.com.au/privacypolicy" target="_blank" className="text-link">http://www.audi.com.au/privacypolicy</a>.
                    If you would like to know more about our privacy policy and procedures and the management of your personal information, or if you would like to access or update your personal information, please contact our customer assistance team, T +1800 50 AUDI (2834), E customerassistance@audi-info.com.au
                </p>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeForm);
